// Información Contable

export const estadoSituacionFinanciera = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20contable/EstadodeSituacionFinanciera.pdf"
                    }        
                ]
            },
          {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/Informacion%20contable/EstadodeSituacionFinanciera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20contable/EstadodeSituacionFinanciera.pdf"
                    }
                ]
            },
            {
            
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T4/Informacion%20contable/notasalosestadosfinancieros.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20contable/EstadodeSituacionFinanciera.pdf"
                    }        
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20contable/EstadodeSituacionFinanciera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20contable/EstadodeSituacionFinanciera.pdf"
                    }
                ]
            },
            {
            
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Informacion%20contable/EstadodeSituacionFinanciera.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion contable/EstadodeSituaciónFinanciera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion contable/EstadodeSituaciónFinanciera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion contable/EstadodeSituacionFinanciera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion contable/EstadodeSituaciónFinanciera.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }

];
export const estadoActividades = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20contable/EstadodeActividades.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/Informacion%20contable/EstadodeActividades.pdf"
                    }
                ]
            },
           {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20contable/EstadodeActividades.pdf"
                    }
                ]
            },
             {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T4/Informacion%20contable/EstadodeActividades.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20contable/EstadodeActividades.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20contable/EstadodeActividades.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20contable/EstadodeActividades.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Informacion%20contable/EstadodeActividades.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion contable/EstadodeActividades.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion contable/EstadodeActividades.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion contable/EstadodeActividades.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion contable/EstadodeActividades.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const estadoVariacionHaciendaPublica = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20contable/EstadodeVariacionenlaHaciendaPublica.pdf"
                    }
                ]
            },
           {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/Informacion%20contable/EstadodeVariacionenlaHaciendaPublica.pdf"
                    }
                ]
            },
             {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20contable/EstadodeVariacionenlaHaciendaPublica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T4/Informacion%20contable/EstadodeVariacionenlaHaciendaPublica.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20contable/EstadodeVariacionenlaHaciendaPublica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20contable/EstadodeVariacionenlaHaciendaPublica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20contable/EstadodeVariacionenlaHaciendaPublica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Informacion%20contable/EstadodeVariacionenlaHaciendaPublica.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion contable/EstadodeVariaciónenlaHaciendaPublica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion contable/EstadodeVariaciónenlaHaciendaPublica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion contable/EstadodelaVariacionenlaHaciendaPublica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion contable/EstadodeVariaciónenlaHaciendaPublica.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const estadoCambiosSituacionFinanciera = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20contable/EstadodeCambiosenlaSituaciónFinanciera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/Informacion%20contable/EstadodeCambiosenlaSituacionFinanciera.pdf"
                    }
                ]
            },
           {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20contable/EstadodeCambiosenlaSituacionFinanciera.pdf"
                    }
                ]
            },
             {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T4/Informacion%20contable/EstadodeCambiosenlaSituacionFinanciera.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20contable/EstadodeCambiosenlaSituaciónFinanciera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20contable/EstadodeCambiosenlaSituacionFinanciera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20contable/EstadodeCambiosenlaSituacionFinanciera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Informacion%20contable/EstadodeCambiosenlaSituacionFinanciera.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                       "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion contable/EstadodeCambiosenlaSituacionFinanciera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                       "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion contable/EstadodeCambiosenlaSituaciónFinanciera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion contable/EstadodeCambiosenlaSituacionFinanciera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion contable/EstadodeCambiosenlaSituaciónFinanciera.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Cmabios%20en%20la%20Situaci%C3%B3n%20Financiera.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const informePasivosContingentes = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20contable/InformesobrePasivosContingentes.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/Informacion%20contable/InformesobrePasivosContingentes.pdf"
                    }
                ]
            },
           {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20contable/InformesobrePasivosContingentes.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20contable/InformesobrePasivosContingentes.pdf"
                    }
                ]
            },     
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20contable/PasivosContingentes.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20contable/PasivosContingentes.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20contable/PasivosContingentes.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20contable/PasivosContingentes.pdf"
                    }
                ]
            },       
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion contable/PasivosContigentesNA.pdf"
            
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion contable/PasivosContigentesNA.pdf" 
                       
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion contable/PasivosContingentes.pdf"
                        
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                         "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion contable/PasivosContigentes.pdf"
                        
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/informe%20pasivos%20contingentes.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const notasEstadosFinancieros = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20contable/notasalosestadosfinancieros.pdf"
                    }
                ]
            },
           {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA//2024/transparencia/Armonizacioncontable/T2/Informacion%20contable/notasalosestadosfinancieros.pdf"
                    }
                ]
            },
            {
                 "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20contable/notasalosestadosfinancieros.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T4/Informacion%20contable/notasalosestadosfinancieros.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20contable/NotasalosEstadosFinancieros.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20contable/NotasalosEstadosFinancieros.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20contable/NotasalosEstadosFinancieros.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Informacion%20contable/NotasalosEstadosFinandieros.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion contable/NotasalosEstadosFinancieros.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion contable/NotasalosEstadosFinancieros.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion contable/NotasenlosEstadosFinancieros.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion contable/NotasalosEstadosFinancieros.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/notas%20Estados%20Financieros.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const estadoAnalíticoActivo = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20contable/EstadoAnaliticodelActivo.pdf"
                    }
                ]
            },
          {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/Informacion%20contable/EstadoAnaliticodelActivo.pdf"
                    }
                ]
            },
              {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20contable/EstadoAnaliticodelActivo.pdf"
                    }
                ]
            },
            
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T4/Informacion%20contable/EstadoAnaliticodelActivo.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20contable/EstadoAnaliticodelActivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20contable/EstadoAnaliticodelActivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20contable/EstadoAnaliticodelActivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Informacion%20contable/EstadoAnaliticodelActivo.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion contable/EstadoAnaliticodelActivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion contable/EstadoAnaliticodelActivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                       "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion contable/EstadoAnaliticodelActivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion contable/EstadoAnaliticodelActivo.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/contable/Estado%20Analitico%20del%20Activo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const estadoFlujosEfectivo = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20contable/EstadodeFlujosdeEfectivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/Informacion%20contable/EstadodeFlujosdeEfectivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20contable/EstadodeFlujosdeEfectivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T4/Informacion%20contable/EstadodeFlujosdeEfectivo.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20contable/EstadodeFlujosdeEfectivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20contable/EstadodeFlujosdeEfecivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20contable/EstadodeFlujosdeEfecivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Informacion%20contable/EstadodeFlujosdeEfectivo.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion contable/EstadodeFlujosdeEfectivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion contable/EstadodeFlujosdeEfectivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion contable/EstadodeFlujosdeEfectivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion contable/EstadodeFlujosdeEfectivo.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const estadoAnaliticoDeudaOtrosPasivo = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20contable/EstadoAnaliticodelaDeudayOtrosPasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/Informacion%20contable/EstadoAnaliticodelaDeudayOtrosPasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20contable/EstadoAnaliticodelaDeudayOtrosPasivos.pdf"
                    }
                ]
            },
            
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T4/Informacion%20contable/EstadoAnaliticodelaDeudayOtrosPasivos.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20contable/EstadoAnaliticodelaDeudayOtrosPasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20contable/EstadoAnaliticodelaDeudayOtrosPasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20contable/EstadoAnaliticodelaDeudayOtrosPasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Informacion%20contable/EstadoAnaliticodelaDeudayOtrosPasivos.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion contable/EstadoAnaliticodelaDeudayOtrosPasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion contable/EstadoAnaliticodelaDeudayOtrosPasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion contable/EstadoAnaliticodelaDeudayOtrosPasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion contable/EstadoAnaliticodelaDeudayOtrosPasivos.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estad%20Flujo%20Efectivo.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const conciliacionContablePresupuestal = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20contable/ConciliacionentrelosIngresosPesupuestariosyContable.pdf"
                    }
                ]
            },
           {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/Informacion%20contable/ConciliacionentrelosIngresosPresupuestariosyContable.pdf"
                    }
                ]
            },
             {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20contable/ConciliacionentrelosIngresosPresupuestariosyContable.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T4/Informacion%20contable/ConciliacionentrelosIngresosPresupuestariosyContable.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20contable/ConciliacionentrelosIngresosPresupuestariosyContables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20contable/ConciliacionentrelosIngresosPresupuestariosyContables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20contable/ConciliacionentrelosIngresosPresupuestariosyContables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Informacion%20contable/ConciliacionentrelosIngresosPresupuestariosyContables.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion contable/ConciliaciónentrelosIngresosPresupuestalesyContables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                       "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion contable/ConciliaciónentrelosIngresosPresupuestalesyContables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion contable/ConciliacionentrelosIngresosPresupuestalesycontables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion contable/ConciliacionentrelosIngresosPresupuestariosyContables.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const conciliacionContablePresupuestalE = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20contable/ConciliacionentrelosEgresosPresupuestariosyylosGastosContable.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/Informacion%20contable/ConciliacionentrelosEgresosPresupuestariosyylosGastosContable.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20contable/ConciliacionentrelosEgresosPresupuestariosyylosGastosContable.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T4/Informacion%20contable/ConciliacionentrelosEgresosPresupuestariosyylosGastosContable.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20contable/ConciliacionentrelosEgresosPresupuestariosylosGastosContables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20contable/ConciliacionentrelosEgresosPresupuestariosylosGastosContables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20contable/ConciliacionentrelosEgresosPresupuestariosylosGastosContables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Informacion%20contable/ConciliacionentrelosEgresosPresupuestariosylosGastosContables.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion contable/ConciliacionentrelosEgresosPresupuestalesyContables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion contable/ConciliacionentrelosEgresosPresupuestalesyContables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion contable/ConciliacionentrelosEgresosPresupuestalesycontables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion contable/ConciliacionentrelosEgresosPresupuestariosylosGastosContables.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];

export const edoAnaliticoPrestoEgresosClasifObjetoGasto = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/dir1/2023/transparencia/informacioncontable/T1/Informacion%20contable/EstadodeSitucionFinanciera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/dir1/2023/transparencia/informacioncontable/T2/Informacion%20contable/EstadodeSitucionFinanciera.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion contable/ConciliacionentrelosEgresosPresupuestalesyContables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    }
];
export const edoAnaliticodelPrestoEgresosClasifEconimica = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    }
];
export const edoAnaliticodelPrestoEgresosClasifAdministrativa = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    }
];

// Información Presupuestaría

export const estadoAnaliticoIngresos = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20presupuestal/EstadoAnaliticodeIngresos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/Informacion%20presupuestal/EstadoAnaliticodeIngresos.pdf"
                        
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20presupuestal/EstadoAnaliticodeIngresos.pdf"
                        
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T4/Informacion%20presupuestal/EstadoAnaliticodeIngresos.pdf"
                        
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20presupuestal/EstadoAnaliticodeIngresos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20presupuestal/EstadoAnaliticodeIngresos.pdf"
                        
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20presupuestal/EstadoAnaliticodeIngresos.pdf"
                        
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Informacion%20presupuestal/EstadoAnaliticodeIngresos.pdf"
                        
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion contable/EstadoAnaliticodeIngresos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion contable/EstadoAnaliticodeIngresos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion contable/EstadoAnaliticodeIngresos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion contable/EstadoAnaliticodeIngresos.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Estado%20Analitico%20de%20Ingresos.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const estadoAnaliticoPresupuestoEgresosCE = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20presupuestal/EstadoAnaliticodelEjercicioPresupuestodeEgresosClasificacionEconomica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/Informacion%20presupuestal/EstadoAnaliticodelEjercicioPresupuestodeEgresosClasificacionEconomica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionEconomica.pdf"
                    }
                ]
            },
            
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T4/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionEconomica.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionEconomica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionEconomica.pdf"
                    }
                ]
            },{
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionEconomica.pdf"
                    }
                ]
            },{
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionEconomica.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA//2022/transparencia/informacioncontable/T1/Informacion presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificaciónEconomica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion presupuestal/EstadoPresupuestoEgresosClasificaciónEconomica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion presupuestal/EstadoPresupuestodeEgresoClasificacionEconomica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificaciónEconomica.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Economica.PDF "
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const estadoAnaliticoPresupuestoEgresosCOG = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20presupuestal/EstadoAnaliticodelEjercicioPresupuestodeEgresosClasificacionPorobjetodelgasto.pdf"
                    }
                ]
            },
           {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionporObjetodelGastoCapituloyConcepto.pdf"
                    }
                ]
            },
            
             {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionporObjetodelGastoCapituloyConcepto.pdf"
                    }
                ]
            },
            
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T4/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionporObjetodelGastoCapituloyConcepto.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionporObjetodelGastoCapituloyConcepto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionporObjetodelGastoCapituloyConcepto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionporObjetodelGastoCapituloyConcepto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionporObjetodelGasto.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionporObjetodelGastoCapituloyConcepto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion presupuestal/EstadoAnaliticodelEjercicioPresupuestodeEgresosClasicicaciónporObjetodelgastoCAPITULOYCONCEPTO.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion presupuestal/EstadoAnaliticodelEjercicioPresupuestodeEgresosClasificacionporObjetodelGastoCapituloyConcepto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionporObjetodelGastoCapiutuloyConcepto.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Objeto%20del%20Gasto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const InteresesDeuda = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20presupuestal/InteresesdelaDeuda.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/Informacion%20presupuestal/InteresdelaDeuda.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20presupuestal/InteresesdelaDeuda.pdf"
                    }
                ]
            },
            
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T4/Informacion%20presupuestal/InteresesdelaDeuda.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20presupuestal/Interesesdeladeuda.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20presupuestal/Interesesdeladeuda.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20presupuestal/Interesesdeladeuda.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Informacion%20presupuestal/InteresdelaDeuda.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion presupuestal/Interesesdeladeuda.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion presupuestal/InteresesdelaDeuda.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion presupuestal/InteresesdelaDeuda.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion presupuestal/Interesesdeladeuda.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Intereses%20de%20la%20Deuda.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const EstadoAnaliticoPresupuestoEgresosCF = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20presupuestal/EstadoAnaliticodelEjercicioPresupuestodeEgresosClasificacionFuncional.pdf"
                        
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/Informacion%20presupuestal/EstadoAnaliticodelEjercicioPresupuestodeEgresosClasificacionFuncional.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionFuncional.pdf"
                    }
                ]
            },
            
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T4/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionFuncional.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionFuncional.pdf"
                        
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionFuncional.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionFuncional.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionFuncional.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionFuncional.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion presupuestal/EstadoPresupuestoEgresosClasificacionFuncional.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion presupuestal/EstadoPresupuestodeEgresosClasificacionFuncional.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionFuncional.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Pto%20Egresos%20Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const EstadoAnaliticoPresupuestoEgresosCA = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20presupuestal/EstadoAnaliticodelEjercicioPresupuestodeEgresosClasificacionAdministrativa.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/Informacion%20presupuestal/EstadoAnaliticodelEjercicioPresupuestodeEgresosClasificacionAdministrativa.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20presupuestal/EstadoAnaliticodelEjercicioPresupuestodeEgresosClasificacionAdministrativa.pdf"
                    }
                ]
            },
            
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T4/Informacion%20presupuestal/EstadoAnaliticodelEjercicioPresupuestodeEgresosClasificacionAdministrativa.pdf",
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionAdministrativa.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionAdministrativa.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionAdministrativa.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Informacion%20presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionAdministrativa.pdf",
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionAdministrativa.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion presupuestal/EstadoAnaliticodelEjercicioPresupuestodelEjercicioClasificacionAdministrativa.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion presupuestal/EstadoAnaliticodelEjercicioPresupuestodeEgresosClasificacion Administrativa.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion presupuestal/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionAdministrativa.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Clasificacion%20Funcional.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const EndeudamientoNeto = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20presupuestal/EndeudamientoNeto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/Informacion%20presupuestal/EndeudamientoNeto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20presupuestal/EndeudamientoNeto.pdf"
                    }
                ]
            },
            
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T4/Informacion%20presupuestal/EndeudamientoNeto.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20presupuestal/EndeudamientoNeto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20presupuestal/EndeudamientoNeto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20presupuestal/EndeudamientoNeto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Informacion%20presupuestal/EndeudamientoNeto.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion contable/EndeudamientoNeto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion contable/EndeudamientoNeto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion contable/EndeudamientoNeto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion contable/EndeudamientoNeto.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                         //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Endeudamiento%20Neto.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const EstadoAnaliticoEjercicioPresupuestoEgresos = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/2do_trim/presupuesta/Endeudamiento%20Neto.PDF"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/3er_trim/presupuestal/Endeudamiento%20Neto.PDF"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/4to_trim/presupuestaria/Endeudamiento%20Neto.PDF"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    }
];
export const EstadoAnaliticoPresupuestoEgresosClasificacionProgramatica = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    }
];
export const EstadoAnaliticoEjercicioPresupuestoFuenteFinanciamiento = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/3er_trim/presupuestal/Estado%20Analitico%20Pto%20Egresos%20Fuente%20de%20Financiamiento.PDF"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/4to_trim/presupuestaria/Edo%20Analit%20Pto%20Egresos%20Fuente%20de%20Financiamiento.PDF"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    }
];
export const EstadoFinancieroPresupuestal = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    }
];

//Información Programática

export const GastoCategoriaProgramatica = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20programatica/GastoporCategoriaProgramatica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/Informacion%20programatica/GastoporCategoriaProgramatica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20programatica/GastoporCategoriaProgramatica.pdf"
                    }
                ]
            },
            
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T4/Informacion%20programatica/GastoporCategoriaProgramatica.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20programatica/GastoporCategoriaProgramatica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20programatica/GastoporCategoriaProgramatica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20programatica/GastoporCategoriaProgramatica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Informacion%20programatica/GastoporCategoriaProgramatica.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion programatica/GastoPorCategoriaProgramatica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion programatica/GastoPorCategoriaProgramatica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion programatica/GastoporCategoriaProgramatica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion programatica/GastoPorCategoriaProgramatica.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const IndicadoresResultados = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20programatica/IndicadoresdeResultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/Informacion%20programatica/IndicadoresdeResultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20programatica/ndicadoresdeResultados.pdf"
                    }
                ]
            },
            
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T4/Informacion%20programatica/IndicadoresdeResultados.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20programatica/IndicadoresdeResultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20programatica/IndicadoresdeResultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20programatica/IndicadoresdeResultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20programatica/IndicadoresdeResultados.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion programatica/IndicadoresdeResultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion programatica/IndicadoresDeResultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion programatica/IndicadoresdeResultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion programatica/Indicadoresderesultados.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2021/1er_trim/programatica/JGSO.13.18.%20Indicadores%20de%20Resultados.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const ProgramasProyectosInversion = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20programatica/ProgramasyProyectosdeInversion.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/Informacion%20programatica/ProgramasyProyectosdeInversion.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20programatica/ProgramasyProyectosdeInversion.pdf"
                    }
                ]
            },
            
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T4/Informacion%20programatica/ProgramasyProyectosdeInversion.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20programatica/ProgramasyProyectosdeInversion.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20programatica/ProgramasyProyectosdeInversion.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20programatica/ProgramasyProyectosdeInversion.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Informacion%20programatica/ProgramasyProyectosdeInversion.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion programatica/ProgramasyProyectosde Inversión.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion programatica/ProgramasyProyectosDeInversión.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion programatica/ProgramasyProyectosdeInversion.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion programatica/Programasyproyectosdeinversión.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/programatica/Programas%20y%20Proyectos%20de%20Inversi%C3%B3n.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const IndicadoresPosturaFiscal = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/Informacion%20presupuestal/IndicadoresdePosturaFiscal.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/Informacion%20presupuestal/IndicadoresdePosturaFiscal.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T3/Informacion%20presupuestal/IndicadoresdePosturaFiscal.pdf"
                    }
                ]
            },
            
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T4/Informacion%20presupuestal/IndicadoresdePosturaFiscal.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Informacion%20presupuestal/IndicadoresdePosturaFiscal.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Informacion%20presupuestal/IndicadoresdePosturaFiscal.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Informacion%20presupuestal/IndicadoresdePosturaFiscal.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Informacion%20presupuestal/FlujodeFondos(indicadoresdeposturafiscal).pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T1/Informacion presupuestal/FlujodeFondos(IndicadoresdePosturaFiscal).pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T2/Informacion presupuestal/FlujodeFondos(IndicadordePosturaFiscal).pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T3/Informacion presupuestal/FlujodeFondos(IndicadoresdePosturaFiscal).pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/transparencia/informacioncontable/T4/Informacion presupuestal/IndicadoresdePosturaFiscal.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Indicadores%20de%20Postura%20Fiscal.PDF"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];

//Otras Obligaciones LGCG

export const ProgramaAnualEvaluaciones = [
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Año 2022",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const ResultadoProgramaAnualEvaluaciones = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];

//Inventario de Bienes

export const InventarioBienesMuebles = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/inventarios/01 RBM.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/2do_trim/anexos/RBMI_IHM_04_2022.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/inventarios/01 RBM.pdf",
                    }
                ]
            },
            /*{
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Inventarios/InventarioBienesMuebles.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Inventarios/InventarioBienesMuebles.pdf",
                    }
                ]
            },*/
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Inventarios/InventarioBienesMuebles.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/2do_trim/anexos/RBMI_IHM_04_2022.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Inventarios/InventarioBienesMuebles.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Inventarios/InventarioBienesMuebles.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Inventarios/InventarioBienesMuebles.pdf",
                    }
                ]
            },
        ],
    },

    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Inventarios/InventarioBienesMuebles.pdf",
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/2do_trim/anexos/RBMI_IHM_04_2022.xlsx",
                        //"pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Inventarios/InventarioBienesMuebles.pdf",
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]    
            },
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]    
            }
        ],
    },
    {
       "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const InventarioBienesImuebles = [
    {
        "anio" : "2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T1/inventarios/02 RBI.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/2do_trim/anexos/RBMI_IHM_04_2022.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/T2/inventarios/02 RBI.pdf",
                    }
                ]
            },
            /*{
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Inventarios/InventarioBienesMuebles.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Inventarios/InventarioBienesMuebles.pdf",
                    }
                ]
            },*/
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Inventarios/InventarioBienesInmuebles.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/2do_trim/anexos/RBMI_IHM_04_2022.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Inventarios/InventarioBienesInmuebles.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T3/Inventarios/InventarioBienesInmuebles.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T4/Inventarios/InventarioBienesInmuebles.pdf",
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];

//Cuenta Pública 

export const CuentaPublica2022 = [
    
];

export const InformaciónContableCP = [
    {
        "anio" : "2023",
        "pdf_content" : [
            {
                "nombre_collapse" : "Inf. contable - 01 Edo. de actividades",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/1. INFORMACION CONTABLE/EstadodeActividades.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 02 Edo. de situación financiera",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/1. INFORMACION CONTABLE/EstadodeSituaciónFinanciera.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 03 Edo. de variación en la hacienda pública",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/1. INFORMACION CONTABLE/EstadodeVariaciónenlaHaciendaPublica.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 04 Edo. de cambios en la situación financiera",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/1. INFORMACION CONTABLE/EstadodeCambiosenlaSituacionFinanciera.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 05 Edo. de flujos de efectivo",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/1. INFORMACION CONTABLE/EstadodeFlujosdeEfectivo.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 06 Edo. analítico del activo",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/1. INFORMACION CONTABLE/EstadoAnaliticodelActivo.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 07 Edo. analítico de la deuda y otros pasivos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/1. INFORMACION CONTABLE/EstadoAnaliticodelaDeudayOtrosPasivos.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 08 Informe de pasivos contingentes",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/1. INFORMACION CONTABLE/InformedePasivosContingentes.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 09 Notas a los Edos. financieros",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/1. INFORMACION CONTABLE/NotasalosEstadosFinancieros.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 10 Conciliación entre los ingresos presupuestarios y contables",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/1. INFORMACION CONTABLE/ConciliaciónentrelosIngresosyEgresosPresupuestariosyContables.PDF",
            }
        ]
    },
    {
        "anio" : "2022",
        "pdf_content" : [
            {
                "nombre_collapse" : "Inf. contable - 01 Edo. de actividades",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/EstadodeActividades.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 02 Edo. de situación financiera",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/EstadodeSituaciónFinanciera.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 03 Edo. de variación en la hacienda pública",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/EstadodeVariaciónenlaHaciendaPublica.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 04 Edo. de cambios en la situación financiera",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/EstadodeCambiosenlaSituacionFinanciera.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 05 Edo. de flujos de efectivo",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/EstadodeFlujosdeEfectivo.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 06 Edo. analítico del activo",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/EstadoAnaliticodelActivo.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 07 Edo. analítico de la deuda y otros pasivos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/EstadoAnaliticodelaDeudayOtrosPasivos.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 08 Informe de pasivos contingentes",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/InformedePasivosContingentes.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 09 Notas a los Edos. financieros",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/NotasalosEstadosFinancieros.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 10 Conciliación entre los ingresos presupuestarios y contables",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/ConciliaciónentrelosIngresosyEgresosPresupuestariosyContables.PDF",
            }
        ]
    },
     {
        "anio" : "2021",
        "pdf_content" : [
            {
                "nombre_collapse" : "Inf. contable - 01 Edo. de actividades",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/1. INFORMACION CONTABLE/EstadoDeActividades.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 02 Edo. de situación financiera",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/1. INFORMACION CONTABLE/EstadoDeSituacionFinanciera.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 03 Edo. de variación en la hacienda pública",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/1. INFORMACION CONTABLE/EstadoDeVariacionEnLaHaciendaPublica.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 04 Edo. de cambios en la situación financiera",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/1. INFORMACION CONTABLE/EstadoDeCambiosEnLaSituacionFinanciera.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 05 Edo. de flujos de efectivo",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/1. INFORMACION CONTABLE/EstadoDeFlujosDeEfectivo.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 06 Edo. analítico del activo",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/1. INFORMACION CONTABLE/EstadoDeFlujosDeEfectivo.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 07 Edo. analítico de la deuda y otros pasivos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/1. INFORMACION CONTABLE/EstadoAnaliticoDeLaDeudaYOtrosPasivos.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 08 Informe de pasivos contingentes",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/1. INFORMACION CONTABLE/InformeSobrePasivosContingentes.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 09 Notas a los Edos. financieros",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/1. INFORMACION CONTABLE/NotasALosEstadosFinancieros.pdf",
            },
            {
                "nombre_collapse" : "Inf. contable - 10 Conciliación entre los ingresos presupuestarios y contables",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/1. INFORMACION CONTABLE/ConciliacionEntreLosIngresosYEgresosPresupuestariosYContables.PDF",
            }
        ]
    },
    {
        "anio" : "2020",
        "pdf_content" : [
            {
                "nombre_collapse" : "Inf. contable - 01 Edo. de actividades",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/1. INFORMACION CONTABLE/EstadoDeActividades.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 02 Edo. de situación financiera",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/1. INFORMACION CONTABLE/EstadoDeSituacionFinanciera.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 03 Edo. de variación en la hacienda pública",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/1. INFORMACION CONTABLE/EstadoDeVariacionEnLaHaciendaPublica.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 04 Edo. de cambios en la situación financiera",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/1. INFORMACION CONTABLE/EstadoDeCambiosEnLaSituacionFinanciera.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 05 Edo. de flujos de efectivo",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/1. INFORMACION CONTABLE/EstadoDeFlujosDeEfectivo.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 06 Edo. analítico del activo",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/1. INFORMACION CONTABLE/EstadoDeFlujosDeEfectivo.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 07 Edo. analítico de la deuda y otros pasivos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/1. INFORMACION CONTABLE/EstadoAnaliticoDeLaDeudaYOtrosPasivos.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 08 Informe de pasivos contingentes",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/1. INFORMACION CONTABLE/InformeSobrePasivosContingentes.PDF",
            },
            {
                "nombre_collapse" : "Inf. contable - 09 Notas a los Edos. financieros",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/1. INFORMACION CONTABLE/NotasALosEstadosFinancieros.pdf",
            },
            {
                "nombre_collapse" : "Inf. contable - 10 Conciliación entre los ingresos presupuestarios y contables",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/1. INFORMACION CONTABLE/ConciliacionEntreLosIngresosYEgresosPresupuestariosYContables.PDF",
            }
        ]
    },
    {
        "anio" : "2019",
        "pdf_content" : [
            {
                "nombre_collapse" : "Inf. contable - 01 Edo. de actividades",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2019/cuentapublica/1. INFORMACION CONTABLE/EstadoDeActividades.pdf",
            },
            {
                "nombre_collapse" : "Inf. contable - 02 Edo. de situación financiera",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2019/cuentapublica/1. INFORMACION CONTABLE/EstadoDeSituacionFinanciera.pdf",
            },
            {
                "nombre_collapse" : "Inf. contable - 03 Edo. de variación en la hacienda pública",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2019/cuentapublica/1. INFORMACION CONTABLE/EstadoDeVariacionEnLaHaciendaPublica.pdf",
            },
            {
                "nombre_collapse" : "Inf. contable - 04 Edo. de cambios en la situación financiera",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2019/cuentapublica/1. INFORMACION CONTABLE/EstadoDeCambiosEnLaSituacionFinanciera.pdf",
            },
            {
                "nombre_collapse" : "Inf. contable - 05 Edo. de flujos de efectivo",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2019/cuentapublica/1. INFORMACION CONTABLE/EstadoDeFlujosDeEfectivo.pdf",
            },
            {
                "nombre_collapse" : "Inf. contable - 06 Edo. analítico del activo",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2019/cuentapublica/1. INFORMACION CONTABLE/EstadoDeFlujosDeEfectivo.pdf",
            },
            {
                "nombre_collapse" : "Inf. contable - 07 Edo. analítico de la deuda y otros pasivos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2019/cuentapublica/1. INFORMACION CONTABLE/EstadoAnaliticoDeLaDeudaYOtrosPasivos.pdf",
            },
            {
                "nombre_collapse" : "Inf. contable - 08 Informe de pasivos contingentes",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2019/cuentapublica/1. INFORMACION CONTABLE/InformeSobrePasivosContingentes.pdf",
            },
            {
                "nombre_collapse" : "Inf. contable - 09 Notas a los Edos. financieros",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2019/cuentapublica/1. INFORMACION CONTABLE/NotasALosEstadosFinancieros.pdf",
            },
            {
                "nombre_collapse" : "Inf. contable - 10 Conciliación entre los ingresos presupuestarios y contables",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2019/cuentapublica/1. INFORMACION CONTABLE/ConciliacionEntreLosIngresosYEgresosPresupuestariosYContables.pdf",
            }
        ]
    },

];

export const informacionPresupuestalCP = [
    {
        "anio" : "2023",
        "pdf_content" : [
            {
                "nombre_collapse" : "Inf. presupuestal - 01 Edo. analítico de ingresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EstadoAnaliticodeIngresos.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 02 Edo. analítico del ejercicio del presupuesto de egresos clasificación por objeto del gasto (capítulo y concepto)",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosporClasificaciónporObjetodelGasto.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 03 Edo. analítico del ejercicio del presupuesto de egresos clasificación administrativa",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/03%20EAEPE%20-%20CA.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosporClasificaciónAdministrativa.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 04 Clasificación económica de los ingresos, de los gastos y del financiamiento",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/04%20CE%20-%20IGF.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/ClasificacionEconomicadelosIngresosdelosGastosydelFinanciamiento.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 05 Edo. analítico del ejercicio del presupuesto de egresos clasificación económica (por tipo de gasto)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/05%20EAEPE%20-%20CE.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/Edo. analítico del ejercicio del presupuesto de egresos clasificación económica (por tipo de gasto).PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 06 Edo. analítico del ejercicio del presupuesto de egresos clasificación funcional (finalidad y función)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/06%20EAEPE%20-%20CF.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionFuncional.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 07 Endeudamiento neto",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/07%20EN.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EndeudamientoNeto.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 08 Intereses de la deuda",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/08%20ID.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/Intereses de la deuda.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 09 Indicadores de postura fiscal",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/09%20IPF.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/Indicadores de postura fiscal.PDF",
            }
        ]
    },
    {
        "anio" : "2022",
        "pdf_content" : [
            {
                "nombre_collapse" : "Inf. presupuestal - 01 Edo. analítico de ingresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EstadoAnaliticodeIngresos.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 02 Edo. analítico del ejercicio del presupuesto de egresos clasificación por objeto del gasto (capítulo y concepto)",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosporClasificaciónporObjetodelGasto.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 03 Edo. analítico del ejercicio del presupuesto de egresos clasificación administrativa",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/03%20EAEPE%20-%20CA.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosporClasificaciónAdministrativa.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 04 Clasificación económica de los ingresos, de los gastos y del financiamiento",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/04%20CE%20-%20IGF.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/ClasificacionEconomicadelosIngresosdelosGastosydelFinanciamiento.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 05 Edo. analítico del ejercicio del presupuesto de egresos clasificación económica (por tipo de gasto)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/05%20EAEPE%20-%20CE.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/Edo. analítico del ejercicio del presupuesto de egresos clasificación económica (por tipo de gasto).PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 06 Edo. analítico del ejercicio del presupuesto de egresos clasificación funcional (finalidad y función)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/06%20EAEPE%20-%20CF.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosClasificacionFuncional.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 07 Endeudamiento neto",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/07%20EN.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EndeudamientoNeto.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 08 Intereses de la deuda",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/08%20ID.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/Intereses de la deuda.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 09 Indicadores de postura fiscal",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/09%20IPF.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/Indicadores de postura fiscal.PDF",
            }
        ]
    },
    {
        "anio" : "2021",
        "pdf_content" : [
            {
                "nombre_collapse" : "Inf. presupuestal - 01 Edo. analítico de ingresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EstadoAnaliticodeIngresos.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 02 Edo. analítico del ejercicio del presupuesto de egresos clasificación por objeto del gasto (capítulo y concepto)",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EstadoAnaliticodelEjerciciodelPresupuestodeEgresosporClasificaciónporObjetodelGasto.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 03 Edo. analítico del ejercicio del presupuesto de egresos clasificación administrativa",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/03%20EAEPE%20-%20CA.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/Edo. analítico del ejercicio del presupuesto de egresos clasificación administrativa.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 04 Clasificación económica de los ingresos, de los gastos y del financiamiento",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/04%20CE%20-%20IGF.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/Clasificación económica de los ingresos, de los gastos y del financiamiento.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 05 Edo. analítico del ejercicio del presupuesto de egresos clasificación económica (por tipo de gasto)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/05%20EAEPE%20-%20CE.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/Edo. analítico del ejercicio del presupuesto de egresos clasificación económica (por tipo de gasto).PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 06 Edo. analítico del ejercicio del presupuesto de egresos clasificación funcional (finalidad y función)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/06%20EAEPE%20-%20CF.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/Edo. analítico del ejercicio del presupuesto de egresos clasificación funcional (finalidad y función).PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 07 Endeudamiento neto",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/07%20EN.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/Endeudamiento neto.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 08 Intereses de la deuda",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/08%20ID.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/Intereses de la deuda.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 09 Indicadores de postura fiscal",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/09%20IPF.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/Indicadores de postura fiscal.PDF",
            }
        ]
    },
    {
        "anio" : "2020",
        "pdf_content" : [
            {
                "nombre_collapse" : "Inf. presupuestal - 01 Edo. analítico de ingresos",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/01%20EAI.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EstadoAnaliticodeIngresos.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 02 Edo. analítico del ejercicio del presupuesto de egresos clasificación por objeto del gasto (capítulo y concepto)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/02%20EAEPE%20-%20COG.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/Edo. analítico del ejercicio del presupuesto de egresos clasificación por objeto del gasto (capítulo y concepto).PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 03 Edo. analítico del ejercicio del presupuesto de egresos clasificación administrativa",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/03%20EAEPE%20-%20CA.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EstadoAnaliticoDelEjercicioDelPresupuestoDeEgresosClasificacionAdministrativa.pdf.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 04 Clasificación económica de los ingresos, de los gastos y del financiamiento",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/04%20CE%20-%20IGF.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/ClasificacionEconomicaDeLosIngresosDeLosGastosYDelFinanciamiento.pdf.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 05 Edo. analítico del ejercicio del presupuesto de egresos clasificación económica (por tipo de gasto)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/05%20EAEPE%20-%20CE.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EstadoAnaliticoDelEjercicioDelPresupuestodeEgresosClasificacionEconomica.pdf.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 06 Edo. analítico del ejercicio del presupuesto de egresos clasificación funcional (finalidad y función)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/06%20EAEPE%20-%20CF.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EstadoAnalitoDelEjercicioDelPresupuestoDeEgresosClasificacionFuncional.pdf.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 07 Endeudamiento neto",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/07%20EN.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EndeudamientoNeto.pdf.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 08 Intereses de la deuda",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/08%20ID.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/InteresesDeLaDeuda.pdf.PDF",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 09 Indicadores de postura fiscal",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/09%20IPF.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/IndicadoresDePosturaFiscal.pdf.PDF",
            }
        ]
    },
    {
        "anio" : "2019",
        "pdf_content" : [
            {
                "nombre_collapse" : "Inf. presupuestal - 01 Edo. analítico de ingresos",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/01%20EAI.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2019/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EstadoAnaliticoDeIngresos.pdf",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 02 Edo. analítico del ejercicio del presupuesto de egresos clasificación por objeto del gasto (capítulo y concepto)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/02%20EAEPE%20-%20COG.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2019/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EstadoAnaliticoDelEjercicioDelPresupuestoDeEgresosClasificacionPorObjetoDelGasto.pdf",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 03 Edo. analítico del ejercicio del presupuesto de egresos clasificación administrativa",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/03%20EAEPE%20-%20CA.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2019/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EstadoAnaliticoDelEjercicioDelPresupuestoDeEgresosClasificacionAdministrativa.pdf",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 04 Clasificación económica de los ingresos, de los gastos y del financiamiento",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/04%20CE%20-%20IGF.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2019/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/ClasificacionEconomicaDeLosIngresosDeLosGastosYDelFinanciamiento.pdf",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 05 Edo. analítico del ejercicio del presupuesto de egresos clasificación económica (por tipo de gasto)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/05%20EAEPE%20-%20CE.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2019/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EstadoAnaliticoDelEjercicioDelPresupuestodeEgresosClasificacionEconomica.pdf",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 06 Edo. analítico del ejercicio del presupuesto de egresos clasificación funcional (finalidad y función)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/06%20EAEPE%20-%20CF.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2019/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EstadoAnalitoDelEjercicioDelPresupuestoDeEgresosClasificacionFuncional.pdf",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 07 Endeudamiento neto",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/07%20EN.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2019/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/EndeudamientoNeto.pdf",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 08 Intereses de la deuda",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/08%20ID.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2019/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/InteresesDeLaDeuda.pdf",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 09 Indicadores de postura fiscal",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/09%20IPF.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2019/cuentapublica/2.%20INFORMACION%20PRESUPUESTAL/IndicadoresDePosturaFiscal.pdf",
            }
        ]
    },
];

export const informacionProgramaticaCP = [
    {
        "anio":"2023",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. programática - 01 Gasto por categoría programática",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/3.%20INFORMACION%20PROGRAMATICA/01%20GCP.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/3.%20INFORMACION%20PROGRAMATICA/GastoporCategoriaProgramatica.PDF",
            },
            {
                "nombre_collapse" : "Inf. programática - 02 Programas y proyectos de inversión",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/3.%20INFORMACION%20PROGRAMATICA/02%20PyPI.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/3.%20INFORMACION%20PROGRAMATICA/ProgramasyProyectosdeInversion.PDF",
            },
            {
                "nombre_collapse" : "Inf. programática - 03 Indicadores de resultados (indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/3.%20INFORMACION%20PROGRAMATICA/03%20IR.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/3.%20INFORMACION%20PROGRAMATICA/IndicadoresdeResultados.PDF",
            },
        ]
    },
    {
        "anio":"2022",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. programática - 01 Gasto por categoría programática",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/3.%20INFORMACION%20PROGRAMATICA/01%20GCP.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/3.%20INFORMACION%20PROGRAMATICA/GastoporCategoriaProgramatica.PDF",
            },
            {
                "nombre_collapse" : "Inf. programática - 02 Programas y proyectos de inversión",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/3.%20INFORMACION%20PROGRAMATICA/02%20PyPI.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/3.%20INFORMACION%20PROGRAMATICA/ProgramasyProyectosdeInversion.PDF",
            },
            {
                "nombre_collapse" : "Inf. programática - 03 Indicadores de resultados (indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/3.%20INFORMACION%20PROGRAMATICA/03%20IR.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/3.%20INFORMACION%20PROGRAMATICA/IndicadoresdeResultados.PDF",
            },
        ]
    },
    {
        "anio":"2021",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. programática - 01 Gasto por categoría programática",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/3.%20INFORMACION%20PROGRAMATICA/01%20GCP.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2021/cuentapublica/3.%20INFORMACION%20PROGRAMATICA/GastoPorCategoriaProgramatica.PDF",
            },
            {
                "nombre_collapse" : "Inf. programática - 02 Programas y proyectos de inversión",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/3.%20INFORMACION%20PROGRAMATICA/02%20PyPI.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2021/cuentapublica/3.%20INFORMACION%20PROGRAMATICA/ProgramasYProyectosDeInversion.PDF",
            },
            {
                "nombre_collapse" : "Inf. programática - 03 Indicadores de resultados (indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/3.%20INFORMACION%20PROGRAMATICA/03%20IR.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2021/cuentapublica/3.%20INFORMACION%20PROGRAMATICA/IndicadoresDeResultado.PDF",
            },
        ]
    },
    {
        "anio":"2020",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. programática - 01 Gasto por categoría programática",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/3.%20INFORMACION%20PROGRAMATICA/01%20GCP.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2020/cuentapublica/3.%20INFORMACION%20PROGRAMATICA/GastoPortCategoriaProgramatica.pdf.PDF",
            },
            {
                "nombre_collapse" : "Inf. programática - 02 Programas y proyectos de inversión",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/3.%20INFORMACION%20PROGRAMATICA/02%20PyPI.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2020/cuentapublica/3.%20INFORMACION%20PROGRAMATICA/ProgramasYProyectosDeInversion.pdf.PDF",
            },
            {
                "nombre_collapse" : "Inf. programática - 03 Indicadores de resultados (indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/3.%20INFORMACION%20PROGRAMATICA/03%20IR.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2020/cuentapublica/3.%20INFORMACION%20PROGRAMATICA/IndicadoresDeResultados.pdf.PDF",
            },
        ]
    },
    {
        "anio":"2019",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. programática - 01 Gasto por categoría programática",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/3.%20INFORMACION%20PROGRAMATICA/01%20GCP.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2019/cuentapublica/3.%20INFORMACION%20PROGRAMATICA/GastoPortCategoriaProgramatica.pdf",
            },
            {
                "nombre_collapse" : "Inf. programática - 02 Programas y proyectos de inversión",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/3.%20INFORMACION%20PROGRAMATICA/02%20PyPI.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2019/cuentapublica/3.%20INFORMACION%20PROGRAMATICA/ProgramasYProyectosDeInversion.pdf",
            },
            {
                "nombre_collapse" : "Inf. programática - 03 Indicadores de resultados (indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/3.%20INFORMACION%20PROGRAMATICA/03%20IR.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2019/cuentapublica/3.%20INFORMACION%20PROGRAMATICA/IndicadoresDeResultados.pdf",
            },
        ]
    },
];

export const anexosCP = [
    {
        "anio":"2023",
        "pdf_content":[
            {
                "nombre_collapse" : "Anexos - 01 Relación de bienes muebles que componen el patrimonio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/01%20RBM.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/4.%20ANEXOS/RelaciondeBienesMuebles.PDF",
            },
            {
                "nombre_collapse" : "Anexos - 02 Relación de bienes inmuebles que componen el patrimonio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/02%20RBI.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/4.%20ANEXOS/RelaciondeBienesInmuebles.PDF",
            },
            {
                "nombre_collapse" : "Anexos - 03 Relación de cuentas bancarias productivas específicas",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/03%20RCBPE.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/4.%20ANEXOS/RelaciondeCuentasBancariasProductivasEspecificas.PDF",
            },
            {
                "nombre_collapse" : "Anexos - 04 Relación de esquemas bursátiles y de coberturas financieras",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/04%20REByCF.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/4.%20ANEXOS/RelacionDeEsQuemasBursatilesYDeCoberturaFinanciera.PDF",
            },
            {
                "nombre_collapse" : "Anexos - 05 Formato del ejercicio y destino de gasto federalizado y reintegros",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/05%20FEyGFyR.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/4.%20ANEXOS/EjercicioYDestinoDeGastoFederalizadoYReintegros.PDF",
            },
        ]
    },
    {
        "anio":"2022",
        "pdf_content":[
            {
                "nombre_collapse" : "Anexos - 01 Relación de bienes muebles que componen el patrimonio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/01%20RBM.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/4.%20ANEXOS/RelaciondeBienesMuebles.PDF",
            },
            {
                "nombre_collapse" : "Anexos - 02 Relación de bienes inmuebles que componen el patrimonio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/02%20RBI.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/4.%20ANEXOS/RelaciondeBienesInmuebles.PDF",
            },
            {
                "nombre_collapse" : "Anexos - 03 Relación de cuentas bancarias productivas específicas",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/03%20RCBPE.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/4.%20ANEXOS/RelaciondeCuentasBancariasProductivasEspecificas.PDF",
            },
            {
                "nombre_collapse" : "Anexos - 04 Relación de esquemas bursátiles y de coberturas financieras",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/04%20REByCF.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/4.%20ANEXOS/RelaciondeEsquemasBursatilesydeCoberturasFinancieras.PDF",
            },
            {
                "nombre_collapse" : "Anexos - 05 Formato del ejercicio y destino de gasto federalizado y reintegros",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/05%20FEyGFyR.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/4.%20ANEXOS/FormatodelEjercicioyDestinodeGastoFederalizadoyReintegros.PDF",
            },
        ]
    },
    {
        "anio":"2021",
        "pdf_content":[
            {
                "nombre_collapse" : "Anexos - 01 Relación de bienes muebles que componen el patrimonio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/01%20RBM.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/4.%20ANEXOS/RelacionDeBienesMuebles.PDF",
            },
            {
                "nombre_collapse" : "Anexos - 02 Relación de bienes inmuebles que componen el patrimonio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/02%20RBI.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/4.%20ANEXOS/RelacionDeBienesInmuebles.PDF",
            },
            {
                "nombre_collapse" : "Anexos - 03 Relación de cuentas bancarias productivas específicas",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/03%20RCBPE.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/4.%20ANEXOS/RelacionDeCuentasBancariasProductivasEspecificas.PDF",
            },
            {
                "nombre_collapse" : "Anexos - 04 Relación de esquemas bursátiles y de coberturas financieras",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/04%20REByCF.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/4.%20ANEXOS/RelacionDeEsQuemasBursatilesYDeCoberturaFinanciera.PDF",
            },
            {
                "nombre_collapse" : "Anexos - 05 Formato del ejercicio y destino de gasto federalizado y reintegros",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/05%20FEyGFyR.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2021/cuentapublica/4.%20ANEXOS/EjercicioYDestinoDeGastoFederalizadoYReintegros.PDF",
            },
        ]
    },
    {
        "anio":"2020",
        "pdf_content":[
            {
                "nombre_collapse" : "Anexos - 01 Relación de bienes muebles que componen el patrimonio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/01%20RBM.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/4.%20ANEXOS/RelacionDeBienesMuebles.pdf.PDF",
            },
            {
                "nombre_collapse" : "Anexos - 02 Relación de bienes inmuebles que componen el patrimonio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/02%20RBI.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/4.%20ANEXOS/RelacionDeBienesInmuebles.pdf.PDF",
            },
            {
                "nombre_collapse" : "Anexos - 03 Relación de cuentas bancarias productivas específicas",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/03%20RCBPE.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/4.%20ANEXOS/RelacionDeCuientasBancariasProductivasEspecificas.pdf.PDF",
            },
            {
                "nombre_collapse" : "Anexos - 04 Relación de esquemas bursátiles y de coberturas financieras",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/04%20REByCF.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/4.%20ANEXOS/RelacionDeEsquemasBursatilesYCoberturaFinanciera.pdf.PDF",
            },
            {
                "nombre_collapse" : "Anexos - 05 Formato del ejercicio y destino de gasto federalizado y reintegros",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/05%20FEyGFyR.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/4.%20ANEXOS/FormatoDel jercicioYDestinoDeGastoFederalizadoYReintegros.pdf.PDF",
            },
        ]
    },
    {
        "anio":"2019",
        "pdf_content":[
            {
                "nombre_collapse" : "Anexos - 01 Relación de bienes muebles que componen el patrimonio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/01%20RBM.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2019/cuentapublica/4.%20ANEXOS/RelacionDeBienesMuebles.pdf",
            },
            {
                "nombre_collapse" : "Anexos - 02 Relación de bienes inmuebles que componen el patrimonio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/02%20RBI.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2019/cuentapublica/4.%20ANEXOS/RelacionDeBienesInmuebles.pdf",
            },
            {
                "nombre_collapse" : "Anexos - 03 Relación de cuentas bancarias productivas específicas",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/03%20RCBPE.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2019/cuentapublica/4.%20ANEXOS/RelacionDeCuientasBancariasProductivasEspecificas.pdf",
            },
            {
                "nombre_collapse" : "Anexos - 04 Relación de esquemas bursátiles y de coberturas financieras",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/04%20REByCF.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2019/cuentapublica/4.%20ANEXOS/RelacionDeEsquemasBursatilesYCoberturaFinanciera.pdf",
            },
            {
                "nombre_collapse" : "Anexos - 05 Formato del ejercicio y destino de gasto federalizado y reintegros",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/05%20FEyGFyR.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2019/cuentapublica/4.%20ANEXOS/FormatoDel jercicioYDestinoDeGastoFederalizadoYReintegros.pdf",
            },
        ]
    },
];

export const infintegconsol = [
    {
        "anio":"2023",
        "pdf_content":[
            //Información para integrar y consolidar
            {
                "nombre_collapse" : "Inf. para integrar y consolidar - 01 Transferencias, asignaciones, subsidios y otras ayudas",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/5.%20INFORMACION%20PARA%20INTEGRAR%20Y%20CONSOLIDAR/01%20%20ITRSFP.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/5.%20INFORMACION%20PARA%20INTEGRAR%20Y%20CONSOLIDAR/01%20%20ITRSFP.PDF",
            },
            {
                "nombre_collapse" : "Inf. para integrar y consolidar - 02 Aportaciones (del grupo hacienda pública/patrimonio contribuido)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/5.%20INFORMACION%20PARA%20INTEGRAR%20Y%20CONSOLIDAR/02%20APSFP.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/5.%20INFORMACION%20PARA%20INTEGRAR%20Y%20CONSOLIDAR/02 APSFP.PDF",
            },
        ]
    },
    {
        "anio":"2022",
        "pdf_content":[
            //Información para integrar y consolidar
            {
                "nombre_collapse" : "Inf. para integrar y consolidar - 01 Transferencias, asignaciones, subsidios y otras ayudas",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/5.%20INFORMACION%20PARA%20INTEGRAR%20Y%20CONSOLIDAR/01%20%20ITRSFP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. para integrar y consolidar - 02 Aportaciones (del grupo hacienda pública/patrimonio contribuido)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/5.%20INFORMACION%20PARA%20INTEGRAR%20Y%20CONSOLIDAR/02%20APSFP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    },
    {
        "anio":"2021",
        "pdf_content":[
            //Información para integrar y consolidar
            {
                "nombre_collapse" : "Inf. para integrar y consolidar - 01 Transferencias, asignaciones, subsidios y otras ayudas",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/5.%20INFORMACION%20PARA%20INTEGRAR%20Y%20CONSOLIDAR/01%20%20ITRSFP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. para integrar y consolidar - 02 Aportaciones (del grupo hacienda pública/patrimonio contribuido)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/5.%20INFORMACION%20PARA%20INTEGRAR%20Y%20CONSOLIDAR/02%20APSFP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    },
    {
        "anio":"2020",
        "pdf_content":[
            //Información para integrar y consolidar
            {
                "nombre_collapse" : "Inf. para integrar y consolidar - 01 Transferencias, asignaciones, subsidios y otras ayudas",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/5.%20INFORMACION%20PARA%20INTEGRAR%20Y%20CONSOLIDAR/01%20%20ITRSFP.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2020/cuentapublica/5.%20INFORMACIONPARAINTEGRARYCONSOLIDAR/TransferenciasAsignacionesSubsidiosYSubvencionesYPensionesYJubilaciones.pdf",
            },
            {
                "nombre_collapse" : "Inf. para integrar y consolidar - 02 Aportaciones (del grupo hacienda pública/patrimonio contribuido)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/5.%20INFORMACION%20PARA%20INTEGRAR%20Y%20CONSOLIDAR/02%20APSFP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    },
    {
        "anio":"2019",
        "pdf_content":[
            //Información para integrar y consolidar
            {
                "nombre_collapse" : "Inf. para integrar y consolidar - 01 Transferencias, asignaciones, subsidios y otras ayudas",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/5.%20INFORMACION%20PARA%20INTEGRAR%20Y%20CONSOLIDAR/01%20%20ITRSFP.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2019/cuentapublica/5.-InformacionParaIntegrarYConsolidar/TransferenciasAsignacionesSubsidiosYSubvencionesYPensionesYJubilaciones.pdf",
            },
            {
                "nombre_collapse" : "Inf. para integrar y consolidar - 02 Aportaciones (del grupo hacienda pública/patrimonio contribuido)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/5.%20INFORMACION%20PARA%20INTEGRAR%20Y%20CONSOLIDAR/02%20APSFP.PDF",
                "pdf_collapse" : "https://cecultah.hidalgo.gob.mx/temporal/2019/cuentapublica/5.-InformacionParaIntegrarYConsolidar/AportacionesDelGrupoDeHaciendaPublicaPatrimonioContribuido.pdf",
            },
        ]
    },
];

export const leydisciplinafinancieraCP = [
     {
        "anio":"2023",
        "pdf_content":[
            {
                "nombre_collapse" : "LDF - 01 Edo. de Situación Financiera Detallado",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/01%20F1%20ESFD.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/01 F1 ESFD.PDF",
            },
            {
                "nombre_collapse" : "LDF - 02 Inf. Analítico de la Deuda Pública y Otros Pasivos ",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/02%20F2%20IADPyOP.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/02 F2 IADPyOP.PDF",
            },
            {
                "nombre_collapse" : "LDF - 03 Inf. Analítico de Obligaciones Diferentes de Financiamiento ",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/03%20F3%20IAODF.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/03 F3 IAODF.PDF",
            },
            {
                "nombre_collapse" : "LDF - 04 Balance Presupuestario ",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/04%20F4%20BP.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/04 F4 BP.PDF",
            },
            {
                "nombre_collapse" : "LDF - 05 Edo. Analítico de Ingresos Detallado ",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/05%20F5%20EAID.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/05 F5 EAID.PDF",
            },
            {
                "nombre_collapse" : "LDF - 06 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación por Objeto del Gasto (Capítulo y Concepto)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/06%20F6%20a%29%20EAEPED%20-%20COG.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/06 F6 a) EAEPED - COG.PDF",
            },
            {
                "nombre_collapse" : "LDF - 07 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación Administrativa",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/07%20F6%20b%29%20EAEPED%20-%20CA.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/07 F6 b) EAEPED - CA.PDF",
            },
            {
                "nombre_collapse" : "LDF - 08 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación Funcional (Finalidad y Función)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/08%20F6%20c%29EAEPED%20-%20CF.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/08 F6 c)EAEPED - CF.PDF",
            },
            {
                "nombre_collapse" : "LDF - 09 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación de Servicios Personales por Categoría",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/09%20F6%20d%29%20EAEPED%20-%20CSPC.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/09 F6 d) EAEPED - CSPC.PDF",
            },
            {
                "nombre_collapse" : "LDF - 10 Guía de Cumplimiento de la Ley de Disciplina Financiera de las Entidades Federativas y Municipios",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/10%20A3%20GC%20.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/10 A3 GC .PDF",
            },
        ]
    },
    {
        "anio":"2022",
        "pdf_content":[
            {
                "nombre_collapse" : "LDF - 01 Edo. de Situación Financiera Detallado",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/01%20F1%20ESFD.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 02 Inf. Analítico de la Deuda Pública y Otros Pasivos ",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/02%20F2%20IADPyOP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 03 Inf. Analítico de Obligaciones Diferentes de Financiamiento ",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/03%20F3%20IAODF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 04 Balance Presupuestario ",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/04%20F4%20BP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 05 Edo. Analítico de Ingresos Detallado ",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/05%20F5%20EAID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 06 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación por Objeto del Gasto (Capítulo y Concepto)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/06%20F6%20a%29%20EAEPED%20-%20COG.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 07 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación Administrativa",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/07%20F6%20b%29%20EAEPED%20-%20CA.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 08 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación Funcional (Finalidad y Función)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/08%20F6%20c%29EAEPED%20-%20CF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 09 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación de Servicios Personales por Categoría",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/09%20F6%20d%29%20EAEPED%20-%20CSPC.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 10 Guía de Cumplimiento de la Ley de Disciplina Financiera de las Entidades Federativas y Municipios",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/10%20A3%20GC%20.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    },
    {
        "anio":"2021",
        "pdf_content":[
            {
                "nombre_collapse" : "LDF - 01 Edo. de Situación Financiera Detallado",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/01%20F1%20ESFD.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 02 Inf. Analítico de la Deuda Pública y Otros Pasivos ",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/02%20F2%20IADPyOP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 03 Inf. Analítico de Obligaciones Diferentes de Financiamiento ",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/03%20F3%20IAODF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 04 Balance Presupuestario ",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/04%20F4%20BP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 05 Edo. Analítico de Ingresos Detallado ",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/05%20F5%20EAID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 06 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación por Objeto del Gasto (Capítulo y Concepto)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/06%20F6%20a%29%20EAEPED%20-%20COG.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 07 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación Administrativa",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/07%20F6%20b%29%20EAEPED%20-%20CA.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 08 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación Funcional (Finalidad y Función)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/08%20F6%20c%29EAEPED%20-%20CF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 09 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación de Servicios Personales por Categoría",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/09%20F6%20d%29%20EAEPED%20-%20CSPC.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 10 Guía de Cumplimiento de la Ley de Disciplina Financiera de las Entidades Federativas y Municipios",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/10%20A3%20GC%20.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    },
    {
        "anio":"2020",
        "pdf_content":[
            {
                "nombre_collapse" : "LDF - 01 Edo. de Situación Financiera Detallado",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/01%20F1%20ESFD.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 02 Inf. Analítico de la Deuda Pública y Otros Pasivos ",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/02%20F2%20IADPyOP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 03 Inf. Analítico de Obligaciones Diferentes de Financiamiento ",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/03%20F3%20IAODF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 04 Balance Presupuestario ",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/04%20F4%20BP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 05 Edo. Analítico de Ingresos Detallado ",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/05%20F5%20EAID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 06 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación por Objeto del Gasto (Capítulo y Concepto)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/06%20F6%20a%29%20EAEPED%20-%20COG.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 07 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación Administrativa",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/07%20F6%20b%29%20EAEPED%20-%20CA.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 08 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación Funcional (Finalidad y Función)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/08%20F6%20c%29EAEPED%20-%20CF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 09 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación de Servicios Personales por Categoría",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/09%20F6%20d%29%20EAEPED%20-%20CSPC.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 10 Guía de Cumplimiento de la Ley de Disciplina Financiera de las Entidades Federativas y Municipios",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/10%20A3%20GC%20.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    },
    {
        "anio":"2019",
        "pdf_content":[
            {
                "nombre_collapse" : "LDF - 01 Edo. de Situación Financiera Detallado",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/01%20F1%20ESFD.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 02 Inf. Analítico de la Deuda Pública y Otros Pasivos ",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/02%20F2%20IADPyOP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 03 Inf. Analítico de Obligaciones Diferentes de Financiamiento ",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/03%20F3%20IAODF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 04 Balance Presupuestario ",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/04%20F4%20BP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 05 Edo. Analítico de Ingresos Detallado ",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/05%20F5%20EAID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 06 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación por Objeto del Gasto (Capítulo y Concepto)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/06%20F6%20a%29%20EAEPED%20-%20COG.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 07 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación Administrativa",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/07%20F6%20b%29%20EAEPED%20-%20CA.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 08 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación Funcional (Finalidad y Función)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/08%20F6%20c%29EAEPED%20-%20CF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 09 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación de Servicios Personales por Categoría",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/09%20F6%20d%29%20EAEPED%20-%20CSPC.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 10 Guía de Cumplimiento de la Ley de Disciplina Financiera de las Entidades Federativas y Municipios",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/6.%20LEY%20DE%20DISCIPLINA%20FINANCIERA/10%20A3%20GC%20.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    },
];

export const informacionadicionalCP = [
    {
        "anio":"2023",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. Adicional LDF - 01 Balance Presupuestario de Recursos Disponibles Negativo",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/01%20BPRDN.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/7. INFORMACION ADICIONAL LDF/01 BPRDN.PDF",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 02 Aumento o Creación del Gasto del Presupuesto de Egresos Durante el Ejercicio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/02%20ACG%20DEL%20PE%20.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/7. INFORMACION ADICIONAL LDF/02 ACG DEL PE .PDF",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 03 Devengados en el Año que Integra el Pasivo Circulante al Cierre del Ejercicio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/03%20DAIPCCE.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/7. INFORMACION ADICIONAL LDF/03 DAIPCCE.PDF",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 04 Ingresos Excedentes Derivados de Ingresos de Libre Disposición",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/04%20IEDILD.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/7. INFORMACION ADICIONAL LDF/04 IEDILD.PDF",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 05 Contratación de Deuda Pública y Obligaciones",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/05%20CDPyO.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/7. INFORMACION ADICIONAL LDF/05 CDPyO.PDF",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 06 Información Detallada de Contratación de Obligaciones a Corto Plazo",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/06%20IDCOCP.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/7. INFORMACION ADICIONAL LDF/06 IDCOCP.PDF",
            },
        ]
    },
    {
        "anio":"2022",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. Adicional LDF - 01 Balance Presupuestario de Recursos Disponibles Negativo",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/01%20BPRDN.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 02 Aumento o Creación del Gasto del Presupuesto de Egresos Durante el Ejercicio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/02%20ACG%20DEL%20PE%20.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 03 Devengados en el Año que Integra el Pasivo Circulante al Cierre del Ejercicio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/03%20DAIPCCE.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 04 Ingresos Excedentes Derivados de Ingresos de Libre Disposición",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/04%20IEDILD.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 05 Contratación de Deuda Pública y Obligaciones",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/05%20CDPyO.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 06 Información Detallada de Contratación de Obligaciones a Corto Plazo",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/06%20IDCOCP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    },
    {
        "anio":"2021",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. Adicional LDF - 01 Balance Presupuestario de Recursos Disponibles Negativo",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/01%20BPRDN.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 02 Aumento o Creación del Gasto del Presupuesto de Egresos Durante el Ejercicio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/02%20ACG%20DEL%20PE%20.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 03 Devengados en el Año que Integra el Pasivo Circulante al Cierre del Ejercicio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/03%20DAIPCCE.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 04 Ingresos Excedentes Derivados de Ingresos de Libre Disposición",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/04%20IEDILD.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 05 Contratación de Deuda Pública y Obligaciones",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/05%20CDPyO.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 06 Información Detallada de Contratación de Obligaciones a Corto Plazo",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/06%20IDCOCP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    },
    {
        "anio":"2020",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. Adicional LDF - 01 Balance Presupuestario de Recursos Disponibles Negativo",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/01%20BPRDN.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 02 Aumento o Creación del Gasto del Presupuesto de Egresos Durante el Ejercicio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/02%20ACG%20DEL%20PE%20.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 03 Devengados en el Año que Integra el Pasivo Circulante al Cierre del Ejercicio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/03%20DAIPCCE.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 04 Ingresos Excedentes Derivados de Ingresos de Libre Disposición",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/04%20IEDILD.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 05 Contratación de Deuda Pública y Obligaciones",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/05%20CDPyO.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 06 Información Detallada de Contratación de Obligaciones a Corto Plazo",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/06%20IDCOCP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    },
    {
        "anio":"2019",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. Adicional LDF - 01 Balance Presupuestario de Recursos Disponibles Negativo",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/01%20BPRDN.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 02 Aumento o Creación del Gasto del Presupuesto de Egresos Durante el Ejercicio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/02%20ACG%20DEL%20PE%20.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 03 Devengados en el Año que Integra el Pasivo Circulante al Cierre del Ejercicio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/03%20DAIPCCE.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 04 Ingresos Excedentes Derivados de Ingresos de Libre Disposición",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/04%20IEDILD.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 05 Contratación de Deuda Pública y Obligaciones",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/05%20CDPyO.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 06 Información Detallada de Contratación de Obligaciones a Corto Plazo",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/06%20IDCOCP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    },
];

export const informaciondecreto90PEEH = [
    {
        "anio":"2023",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 01 Informe sobre la Situación Económica de las Finanzas Públicas Comparativo del Estado de Situación Financiera",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/01%20Art%2010%20CESF.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/8. INFORMACION DEL PEEH/01 Art 10 CESF.PDF",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 02 Informe sobre la Situación Económica de las Finanzas Públicas Comparativo del Estado Analítico del Ejercicio del Presupuesto de Egresos Clasificación por Objeto del Gasto (Capítulo y Concepto)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/02%20Art%2010%20CEAEPE.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/8. INFORMACION DEL PEEH/02 Art 10 CEAEPE.PDF",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 03 Informe sobre la Situación Económica de las Finanzas Públicas Informe Ejecutivo (Comparativos Situación Financiera y Ejercicio del Presupuesto de Egresos)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/03%20Art%2010%20IEC.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/8. INFORMACION DEL PEEH/03 Art 10 IEC.PDF",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 04 Informe sobre la Situación Económica de las Finanzas Públicas Estado Analítico de Ingresos Presupuestales por rubro",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/04%20Art%2010%20EAIP.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/8. INFORMACION DEL PEEH/04 Art 10 EAIP.PDF",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 05 Informe sobre la Situación Económica de las Finanzas Públicas Informe Ejecutivo (Ingresos)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/05%20Art%2010%20IEI.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/8. INFORMACION DEL PEEH/05 Art 10 IEI.PDF",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 06 Informe sobre la Deuda Pública Variación respecto a la Deuda Pública",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/06%20Art%2010%20VDP.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/8. INFORMACION DEL PEEH/06 Art 10 VDP.PDF",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 07 Indicadores de Resultados (Erogaciones de la política transversal de incorporación de la ciencia, tecnología e innovación y cumplimiento de indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/07%20Art%2014%20EPTICTeIyCID.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/8. INFORMACION DEL PEEH/07 Art 14 EPTICTeIyCID.PDF",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 08 Indicadores de Resultados (Erogaciones de la política transversal de perspectiva para el desarrollo y protección de niñas, niños y adolescentes y cumplimiento de indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/08%20Art%2014%20EPTPDyPNNyAyCID.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/8. INFORMACION DEL PEEH/08 Art 14 EPTPDyPNNyAyCID.PDF",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - Indicadores de Resultados (Erogaciones del Programa Estratégico de Respuesta ante Emergencias de Orden Global)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/09%20Art%2014%20EPTPEREOG.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/8. INFORMACION DEL PEEH/09 Art 14 EPTPEREOG.PDF",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 09 Indicadores de Resultados (Erogaciones de la política transversal de perspectiva de género y cumplimiento de indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/09%20Art%2014%20EPTPGyCID.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/8. INFORMACION DEL PEEH/09 Art 14 EPTPGyCID.PDF",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - Indicadores de Resultados (Erogaciones de la Política Transversal de Reducción de las Desigualdades y la vulnerabilidad)",
                //"pdf_collapse" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/09%20Art%2014%20EPTRVD.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/8. INFORMACION DEL PEEH/09 Art 14 EPTRVD.PDF",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - Indicadores de Desempeño",
                //"pdf_collapse" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/10%20Art%2014%20ID.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/8. INFORMACION DEL PEEH/10 Art 14 ID.PDF",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 11 Indicadores de Resultados (Avances y resultados de la ejecución y evaluación de los programas presupuestarios)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/11%20Art%2019%20AyREyEPP.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/8. INFORMACION DEL PEEH/11 Art 19 AyREyEPP.PDF",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 12 Programas, Proyectos, Obras o Acciones con Aportaciones de Recursos por parte del Gobierno del Estado",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/12%20Art%2023%20PPOARGE.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/8. INFORMACION DEL PEEH/12 Art 23 PPOARGE.PDF",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 15 Avance en los Programas de Reducción del Gasto",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/15%20Art%2052%20APRG.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/8. INFORMACION DEL PEEH/15 Art 52 APRG.PDF",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 16 Tabuladores y Remuneraciones Cubiertas a los Servidores Públicos",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/16%20Art%2060%20TyRCSP.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/8. INFORMACION DEL PEEH/16 Art 60 TyRCSP.PDF",
            },
        ]
    },
    {
        "anio":"2022",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 01 Informe sobre la Situación Económica de las Finanzas Públicas Comparativo del Estado de Situación Financiera",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/01%20Art%2010%20CESF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 02 Informe sobre la Situación Económica de las Finanzas Públicas Comparativo del Estado Analítico del Ejercicio del Presupuesto de Egresos Clasificación por Objeto del Gasto (Capítulo y Concepto)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/02%20Art%2010%20CEAEPE.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 03 Informe sobre la Situación Económica de las Finanzas Públicas Informe Ejecutivo (Comparativos Situación Financiera y Ejercicio del Presupuesto de Egresos)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/03%20Art%2010%20IEC.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 04 Informe sobre la Situación Económica de las Finanzas Públicas Estado Analítico de Ingresos Presupuestales por rubro",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/04%20Art%2010%20EAIP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 05 Informe sobre la Situación Económica de las Finanzas Públicas Informe Ejecutivo (Ingresos)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/05%20Art%2010%20IEI.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 06 Informe sobre la Deuda Pública Variación respecto a la Deuda Pública",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/06%20Art%2010%20VDP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 07 Indicadores de Resultados (Erogaciones de la política transversal de incorporación de la ciencia, tecnología e innovación y cumplimiento de indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/07%20Art%2014%20EPTICTeIyCID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 08 Indicadores de Resultados (Erogaciones de la política transversal de perspectiva para el desarrollo y protección de niñas, niños y adolescentes y cumplimiento de indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/08%20Art%2014%20EPTPDyPNNyAyCID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - Indicadores de Resultados (Erogaciones del Programa Estratégico de Respuesta ante Emergencias de Orden Global)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/09%20Art%2014%20EPTPEREOG.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 09 Indicadores de Resultados (Erogaciones de la política transversal de perspectiva de género y cumplimiento de indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/09%20Art%2014%20EPTPGyCID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - Indicadores de Resultados (Erogaciones de la Política Transversal de Reducción de las Desigualdades y la vulnerabilidad)",
                //"pdf_collapse" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/09%20Art%2014%20EPTRVD.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - Indicadores de Desempeño",
                //"pdf_collapse" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/10%20Art%2014%20ID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 11 Indicadores de Resultados (Avances y resultados de la ejecución y evaluación de los programas presupuestarios)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/11%20Art%2019%20AyREyEPP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 12 Programas, Proyectos, Obras o Acciones con Aportaciones de Recursos por parte del Gobierno del Estado",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/12%20Art%2023%20PPOARGE.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 15 Avance en los Programas de Reducción del Gasto",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/15%20Art%2052%20APRG.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 16 Tabuladores y Remuneraciones Cubiertas a los Servidores Públicos",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/16%20Art%2060%20TyRCSP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    },
    {
        "anio":"2021",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 01 Informe sobre la Situación Económica de las Finanzas Públicas Comparativo del Estado de Situación Financiera",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/01%20Art%2010%20CESF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 02 Informe sobre la Situación Económica de las Finanzas Públicas Comparativo del Estado Analítico del Ejercicio del Presupuesto de Egresos Clasificación por Objeto del Gasto (Capítulo y Concepto)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/02%20Art%2010%20CEAEPE.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 03 Informe sobre la Situación Económica de las Finanzas Públicas Informe Ejecutivo (Comparativos Situación Financiera y Ejercicio del Presupuesto de Egresos)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/03%20Art%2010%20IEC.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 04 Informe sobre la Situación Económica de las Finanzas Públicas Estado Analítico de Ingresos Presupuestales por rubro",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/04%20Art%2010%20EAIP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 05 Informe sobre la Situación Económica de las Finanzas Públicas Informe Ejecutivo (Ingresos)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/05%20Art%2010%20IEI.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 06 Informe sobre la Deuda Pública Variación respecto a la Deuda Pública",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/06%20Art%2010%20VDP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 07 Indicadores de Resultados (Erogaciones de la política transversal de incorporación de la ciencia, tecnología e innovación y cumplimiento de indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/07%20Art%2014%20EPTICTeIyCID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 08 Indicadores de Resultados (Erogaciones de la política transversal de perspectiva para el desarrollo y protección de niñas, niños y adolescentes y cumplimiento de indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/08%20Art%2014%20EPTPDyPNNyAyCID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - Indicadores de Resultados (Erogaciones del Programa Estratégico de Respuesta ante Emergencias de Orden Global)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/09%20Art%2014%20EPTPEREOG.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 09 Indicadores de Resultados (Erogaciones de la política transversal de perspectiva de género y cumplimiento de indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/09%20Art%2014%20EPTPGyCID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - Indicadores de Resultados (Erogaciones de la Política Transversal de Reducción de las Desigualdades y la vulnerabilidad)",
                //"pdf_collapse" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/09%20Art%2014%20EPTRVD.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - Indicadores de Desempeño",
                //"pdf_collapse" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/10%20Art%2014%20ID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 11 Indicadores de Resultados (Avances y resultados de la ejecución y evaluación de los programas presupuestarios)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/11%20Art%2019%20AyREyEPP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 12 Programas, Proyectos, Obras o Acciones con Aportaciones de Recursos por parte del Gobierno del Estado",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/12%20Art%2023%20PPOARGE.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 15 Avance en los Programas de Reducción del Gasto",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/15%20Art%2052%20APRG.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 16 Tabuladores y Remuneraciones Cubiertas a los Servidores Públicos",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/16%20Art%2060%20TyRCSP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    },
    {
        "anio":"2020",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 01 Informe sobre la Situación Económica de las Finanzas Públicas Comparativo del Estado de Situación Financiera",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/01%20Art%2010%20CESF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 02 Informe sobre la Situación Económica de las Finanzas Públicas Comparativo del Estado Analítico del Ejercicio del Presupuesto de Egresos Clasificación por Objeto del Gasto (Capítulo y Concepto)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/02%20Art%2010%20CEAEPE.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 03 Informe sobre la Situación Económica de las Finanzas Públicas Informe Ejecutivo (Comparativos Situación Financiera y Ejercicio del Presupuesto de Egresos)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/03%20Art%2010%20IEC.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 04 Informe sobre la Situación Económica de las Finanzas Públicas Estado Analítico de Ingresos Presupuestales por rubro",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/04%20Art%2010%20EAIP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 05 Informe sobre la Situación Económica de las Finanzas Públicas Informe Ejecutivo (Ingresos)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/05%20Art%2010%20IEI.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 06 Informe sobre la Deuda Pública Variación respecto a la Deuda Pública",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/06%20Art%2010%20VDP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 07 Indicadores de Resultados (Erogaciones de la política transversal de incorporación de la ciencia, tecnología e innovación y cumplimiento de indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/07%20Art%2014%20EPTICTeIyCID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 08 Indicadores de Resultados (Erogaciones de la política transversal de perspectiva para el desarrollo y protección de niñas, niños y adolescentes y cumplimiento de indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/08%20Art%2014%20EPTPDyPNNyAyCID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - Indicadores de Resultados (Erogaciones del Programa Estratégico de Respuesta ante Emergencias de Orden Global)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/09%20Art%2014%20EPTPEREOG.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 09 Indicadores de Resultados (Erogaciones de la política transversal de perspectiva de género y cumplimiento de indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/09%20Art%2014%20EPTPGyCID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - Indicadores de Resultados (Erogaciones de la Política Transversal de Reducción de las Desigualdades y la vulnerabilidad)",
                //"pdf_collapse" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/09%20Art%2014%20EPTRVD.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - Indicadores de Desempeño",
                //"pdf_collapse" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/10%20Art%2014%20ID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 11 Indicadores de Resultados (Avances y resultados de la ejecución y evaluación de los programas presupuestarios)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/11%20Art%2019%20AyREyEPP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 12 Programas, Proyectos, Obras o Acciones con Aportaciones de Recursos por parte del Gobierno del Estado",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/12%20Art%2023%20PPOARGE.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 15 Avance en los Programas de Reducción del Gasto",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/15%20Art%2052%20APRG.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 16 Tabuladores y Remuneraciones Cubiertas a los Servidores Públicos",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/16%20Art%2060%20TyRCSP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    },
    {
        "anio":"2019",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 01 Informe sobre la Situación Económica de las Finanzas Públicas Comparativo del Estado de Situación Financiera",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/01%20Art%2010%20CESF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 02 Informe sobre la Situación Económica de las Finanzas Públicas Comparativo del Estado Analítico del Ejercicio del Presupuesto de Egresos Clasificación por Objeto del Gasto (Capítulo y Concepto)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/02%20Art%2010%20CEAEPE.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 03 Informe sobre la Situación Económica de las Finanzas Públicas Informe Ejecutivo (Comparativos Situación Financiera y Ejercicio del Presupuesto de Egresos)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/03%20Art%2010%20IEC.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 04 Informe sobre la Situación Económica de las Finanzas Públicas Estado Analítico de Ingresos Presupuestales por rubro",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/04%20Art%2010%20EAIP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 05 Informe sobre la Situación Económica de las Finanzas Públicas Informe Ejecutivo (Ingresos)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/05%20Art%2010%20IEI.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 06 Informe sobre la Deuda Pública Variación respecto a la Deuda Pública",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/06%20Art%2010%20VDP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 07 Indicadores de Resultados (Erogaciones de la política transversal de incorporación de la ciencia, tecnología e innovación y cumplimiento de indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/07%20Art%2014%20EPTICTeIyCID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 08 Indicadores de Resultados (Erogaciones de la política transversal de perspectiva para el desarrollo y protección de niñas, niños y adolescentes y cumplimiento de indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/08%20Art%2014%20EPTPDyPNNyAyCID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - Indicadores de Resultados (Erogaciones del Programa Estratégico de Respuesta ante Emergencias de Orden Global)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/09%20Art%2014%20EPTPEREOG.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 09 Indicadores de Resultados (Erogaciones de la política transversal de perspectiva de género y cumplimiento de indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/09%20Art%2014%20EPTPGyCID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - Indicadores de Resultados (Erogaciones de la Política Transversal de Reducción de las Desigualdades y la vulnerabilidad)",
                //"pdf_collapse" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/09%20Art%2014%20EPTRVD.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - Indicadores de Desempeño",
                //"pdf_collapse" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/10%20Art%2014%20ID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 11 Indicadores de Resultados (Avances y resultados de la ejecución y evaluación de los programas presupuestarios)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/11%20Art%2019%20AyREyEPP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 12 Programas, Proyectos, Obras o Acciones con Aportaciones de Recursos por parte del Gobierno del Estado",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/12%20Art%2023%20PPOARGE.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 15 Avance en los Programas de Reducción del Gasto",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/15%20Art%2052%20APRG.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 16 Tabuladores y Remuneraciones Cubiertas a los Servidores Públicos",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/16%20Art%2060%20TyRCSP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    }
];


export const informacionLPCGEH  = [
    {
        "anio":"2023",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 01 Aumento o Creación del Gasto del Presupuesto de Egresos 2Inf. de la LPCGEH - 021 (Etiquetado y No Etiquetado)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/01%20Art%2018%20BIS%20ACG%20.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/9. INFORMACION DE LA LPCGEH/01 Art 18 BIS ACG .PDF",
            },
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 06 Contrataciones por Prestación de Servicios Profesionales por Honorarios (Personas Físicas)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/06%20Art%2072%20CPSPH%20-%20PF.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/9. INFORMACION DE LA LPCGEH/06 Art 72 CPSPH - PF.PDF",
            },
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 07 Indicadores de Resultados (Resultados de los Programas de Igualdad de Género)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/07%20Art%2096%20RPIG.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/9. INFORMACION DE LA LPCGEH/07 Art 96 RPIG.PDF",
            },
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 08 Indicadores de Resultados (Indicadores de Desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/08%20Art%2096%20ID.PDF",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/cuentapublica/9. INFORMACION DE LA LPCGEH/08 Art 96 ID.PDF",
            },
        ]
    },
    {
        "anio":"2022",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 01 Aumento o Creación del Gasto del Presupuesto de Egresos 2Inf. de la LPCGEH - 021 (Etiquetado y No Etiquetado)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/01%20Art%2018%20BIS%20ACG%20.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 06 Contrataciones por Prestación de Servicios Profesionales por Honorarios (Personas Físicas)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/06%20Art%2072%20CPSPH%20-%20PF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 07 Indicadores de Resultados (Resultados de los Programas de Igualdad de Género)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/07%20Art%2096%20RPIG.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 08 Indicadores de Resultados (Indicadores de Desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/08%20Art%2096%20ID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    },
    {
        "anio":"2021",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 01 Aumento o Creación del Gasto del Presupuesto de Egresos 2Inf. de la LPCGEH - 021 (Etiquetado y No Etiquetado)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/01%20Art%2018%20BIS%20ACG%20.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 06 Contrataciones por Prestación de Servicios Profesionales por Honorarios (Personas Físicas)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/06%20Art%2072%20CPSPH%20-%20PF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 07 Indicadores de Resultados (Resultados de los Programas de Igualdad de Género)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/07%20Art%2096%20RPIG.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 08 Indicadores de Resultados (Indicadores de Desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/08%20Art%2096%20ID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    },
    {
        "anio":"2020",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 01 Aumento o Creación del Gasto del Presupuesto de Egresos 2Inf. de la LPCGEH - 021 (Etiquetado y No Etiquetado)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/01%20Art%2018%20BIS%20ACG%20.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 06 Contrataciones por Prestación de Servicios Profesionales por Honorarios (Personas Físicas)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/06%20Art%2072%20CPSPH%20-%20PF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 07 Indicadores de Resultados (Resultados de los Programas de Igualdad de Género)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/07%20Art%2096%20RPIG.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 08 Indicadores de Resultados (Indicadores de Desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/08%20Art%2096%20ID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    },
    {
        "anio":"2019",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 01 Aumento o Creación del Gasto del Presupuesto de Egresos 2Inf. de la LPCGEH - 021 (Etiquetado y No Etiquetado)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/01%20Art%2018%20BIS%20ACG%20.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 06 Contrataciones por Prestación de Servicios Profesionales por Honorarios (Personas Físicas)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/06%20Art%2072%20CPSPH%20-%20PF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 07 Indicadores de Resultados (Resultados de los Programas de Igualdad de Género)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/07%20Art%2096%20RPIG.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 08 Indicadores de Resultados (Indicadores de Desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/08%20Art%2096%20ID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    }
];

//Dictamen

export const DictameN = [

{
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Dictamenes de Auditoria Externa 2023",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/DictamenesdeAuditoriaExterna2023.pdf"
                    }        
                ]
            },
        
        ],
    },

    ];


//Normas Titulo V

export const Normastitulov = [
    {
        "anio" : "2024",
        "pdf_content" : [
            
            {
                "nombre_collapse" : "Norma para armonizar la presentación de la información adicional a la iniciativa de la Ley de Ingresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2024/Cuenta%20Publica/NormaparaarmonizarlapresentacióndelainformaciónadicionalalainiciativadelaLeydeIngresos.pdf",
            },
            {
                "nombre_collapse" : "Norma para armonizar la presentación de la información adicional del Proyecto del Presupuesto de Egresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2024/Cuenta%20Publica/NormaparaarmonizarlapresentacióndelainformaciónadicionaldelProyectodelPresupuestodeEgresos.pdf",
            },
            {
                "nombre_collapse" : "Norma para establecer la estructura del Calendario de Ingresos base mensual",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2024/Cuenta%20Publica/NormaparaestablecerlaestructuradelCalendariodeIngresosbasemensual.pdf",
            },
            {
                "nombre_collapse" : "Norma para establecer la estructura del Calendario del Presupuesto de Egresos base mensual",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2024/Cuenta%20Publica/NormaparaestablecerlaestructuradelCalendariodelPresupuestodeEgresosbasemensual.pdf",
            },
            {
                "nombre_collapse" : "Norma para la difusión a la ciudadanía de la Ley de Ingresos y del Presupuesto de Egresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2024/Cuenta%20Publica/NormaparaladifusiónalaciudadaníadelaLeydeIngresosydelPresupuestodeEgresos.pdf",
            }
          
        ]  
    },
    {
        "anio" : "2023",
        "pdf_content" : [
            
            {
                "nombre_collapse" : "Juntas de Gobierno",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/JuntadeGobierno/Acta%20ISE%202023.PDF",
            },
            {
                "nombre_collapse" : "Norma para armonizar la presentación de la información adicional a la iniciativa de la Ley de Ingresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2023/cuentapublica/1. INFORMACION CONTABLE/NormaparaarmonizarlapresentacióndelainformaciónadicionalalainiciativadelaLeydeIngresos.pdf",
            },
            {
                "nombre_collapse" : "Norma para armonizar la presentación de la información adicional del Proyecto del Presupuesto de Egresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2023/cuentapublica/1. INFORMACION CONTABLE/NormaparaarmonizarlapresentacióndelainformaciónadicionaldelProyectodelPresupuestodeEgresos.pdf",
            },
            {
                "nombre_collapse" : "Norma para establecer la estructura del Calendario de Ingresos base mensual",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2023/cuentapublica/1. INFORMACION CONTABLE/NormaparaestablecerlaestructuradelCalendariodeIngresosbasemensual.pdf",
            },
            {
                "nombre_collapse" : "Norma para establecer la estructura del Calendario del Presupuesto de Egresos base mensual",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2023/cuentapublica/1. INFORMACION CONTABLE/NormaparaestablecerlaestructuradelCalendariodelPresupuestodeEgresosbasemensual.pdf",
            },
            {
                "nombre_collapse" : "Norma para la difusión a la ciudadanía de la Ley de Ingresos y del Presupuesto de Egresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2023/cuentapublica/1. INFORMACION CONTABLE/NormaparaladifusiónalaciudadaníadelaLeydeIngresosydelPresupuestodeEgresos.pdf",
            }
          
        ]  
    },
    {
        "anio" : "2022",
        "pdf_content" : [
            
            {
                "nombre_collapse" : "Indicadores de Resultados T1",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/IndicadoresdeResultadosT1.pdf",
            },
            {
                "nombre_collapse" : "Indicadores de Resultados T2",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/IndicadoresdeResultadosT2pdf",
            },
            {
                "nombre_collapse" : "Indicadores de Resultados T3",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/IndicadoresdeResultadosT3.pdf",
            },
            {
                "nombre_collapse" : "Indicadores de Resultados T4",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/IndicadoresdeResultadosT4.pdf",
            },
            {
                "nombre_collapse" : "Información sobre los montos pagados durante el periodo por concepto Ayudas Sociales y Subsidios 1er",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/InformaciónsobrelosmontospagadosduranteelperiodoporconceptoAyudasSocialesySubsidios1er.pdf",
            },
            {
                "nombre_collapse" : "Información sobre los montos pagados durante el periodo por concepto Ayudas Sociales y Subsidios 2do",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/InformaciónsobrelosmontospagadosduranteelperiodoporconceptoAyudasSocialesySubsidios2do.pdf",
            },
            {
                "nombre_collapse" : "Información sobre los montos pagados durante el periodo por concepto Ayudas Sociales y Subsidios 3ero",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/InformaciónsobrelosmontospagadosduranteelperiodoporconceptoAyudasSocialesySubsidios3ero.pdf",
            },
            {
                "nombre_collapse" : "Información sobre los montos pagados durante el periodo por concepto Ayudas Sociales y Subsidios 4to",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/InformaciónsobrelosmontospagadosduranteelperiodoporconceptoAyudasSocialesySubsidios4to.pdf",
            },
            {
                "nombre_collapse" : "Información sobre los montos pagados durante el periodo por concepto Ayudas Sociales y Subsidios 4.1to",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/InformaciónsobrelosmontospagadosduranteelperiodoporconceptoAyudasSocialesySubsidios4.1to.pdf",
            },
            {
                "nombre_collapse" : "Norma para armonizar la presentación de la información adicional a la iniciativa de la Ley de Ingresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/NormaparaarmonizarlapresentacióndelainformaciónadicionalalainiciativadelaLeydeIngresos.pdf",
            },
            {
                "nombre_collapse" : "Norma para armonizar la presentación de la información adicional del Proyecto del Presupuesto de Egresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/NormaparaarmonizarlapresentacióndelainformaciónadicionaldelProyectodelPresupuestodeEgresos.pdf",
            },
            {
                "nombre_collapse" : "Norma para establecer la estructura del Calendario de Ingresos base mensual",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/NormaparaestablecerlaestructuradelCalendariodeIngresosbasemensual.pdf",
            },
            {
                "nombre_collapse" : "Norma para establecer la estructura del Calendario del Presupuesto de Egresos base mensual",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/NormaparaestablecerlaestructuradelCalendariodelPresupuestodeEgresosbasemensual.pdf",
            },
            {
                "nombre_collapse" : "Norma para la difusión a la ciudadanía de la Ley de Ingresos y del Presupuesto de Egresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/NormaparaladifusiónalaciudadaníadelaLeydeIngresosydelPresupuestodeEgresos.pdf",
            },
            {
                "nombre_collapse" : "Reporte de Analisis de Desempeño Institucional 2do Semestre 2022",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/ReportedeAnalisisdeDesempeñoInstitucional2doSemestre2022.pdf",
            },
            {
                "nombre_collapse" : "Reporte de Analisis de Desempeño Institucional 1er Semestre 2022",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/ReportedeAnalisisdelDesempeñoInstitucionaldel1erSemestre2022.pdf",
            },
        ]  
    }
];

/*
export const informacionNormasTVA = [
    {
        "anio" : "2023",
        "pdf_content" : [
            {
                "nombre_collapse" : "Norma para armonizar la presentación de la información adicional a la iniciativa de la Ley de Ingresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2023/cuentapublica/1. INFORMACION CONTABLE/NormaparaarmonizarlapresentacióndelainformaciónadicionalalainiciativadelaLeydeIngresos.pdf",
            },
            {
                "nombre_collapse" : "Norma para armonizar la presentación de la información adicional del Proyecto del Presupuesto de Egresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2023/cuentapublica/1. INFORMACION CONTABLE/NormaparaarmonizarlapresentacióndelainformaciónadicionaldelProyectodelPresupuestodeEgresos.pdf",
            },
            {
                "nombre_collapse" : "Norma para establecer la estructura del Calendario de Ingresos base mensual",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2023/cuentapublica/1. INFORMACION CONTABLE/NormaparaestablecerlaestructuradelCalendariodeIngresosbasemensual.pdf",
            },
            {
                "nombre_collapse" : "Norma para establecer la estructura del Calendario del Presupuesto de Egresos base mensual",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2023/cuentapublica/1. INFORMACION CONTABLE/NormaparaestablecerlaestructuradelCalendariodelPresupuestodeEgresosbasemensual.pdf",
            },
            {
                "nombre_collapse" : "Norma para la difusión a la ciudadanía de la Ley de Ingresos y del Presupuesto de Egresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2023/cuentapublica/1. INFORMACION CONTABLE/NormaparaladifusiónalaciudadaníadelaLeydeIngresosydelPresupuestodeEgresos.pdf",
            }
        ]
    },
    {
        "anio" : "2022",
        "pdf_content" : [
            {
                "nombre_collapse" : "Norma para armonizar la presentación de la información adicional a la iniciativa de la Ley de Ingresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/NormaparaarmonizarlapresentacióndelainformaciónadicionalalainiciativadelaLeydeIngresos.pdf",
            },
            {
                "nombre_collapse" : "Norma para armonizar la presentación de la información adicional del Proyecto del Presupuesto de Egresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/NormaparaarmonizarlapresentacióndelainformaciónadicionaldelProyectodelPresupuestodeEgresos.pdf",
            },
            {
                "nombre_collapse" : "Norma para establecer la estructura del Calendario de Ingresos base mensual",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/NormaparaestablecerlaestructuradelCalendariodeIngresosbasemensual.pdf",
            },
            {
                "nombre_collapse" : "Norma para establecer la estructura del Calendario del Presupuesto de Egresos base mensual",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/NormaparaestablecerlaestructuradelCalendariodelPresupuestodeEgresosbasemensual.pdf",
            },
            {
                "nombre_collapse" : "Norma para la difusión a la ciudadanía de la Ley de Ingresos y del Presupuesto de Egresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/NormaparaladifusiónalaciudadaníadelaLeydeIngresosydelPresupuestodeEgresos.pdf",
            }
        ]
    },

    ]
*/


/*
export const informacionNormasTVS = [
     {
        "anio" : "2022",
        "pdf_content" : [
            {
                "nombre_collapse" : "Reporte de Analisis de Desempeño Institucional 2do Semestre 2022",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/ReportedeAnalisisdeDesempeñoInstitucional2doSemestre2022.pdf",
            },
            {
                "nombre_collapse" : "Reporte de Analisis de Desempeño Institucional 1er Semestre 2022",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/ReportedeAnalisisdelDesempeñoInstitucionaldel1erSemestre2022.pdf",
            }

        ]  
    },

    ]
*/

/*
export const informacionNormasTVT = [
    {
        "anio":"2022 AYUDAS SOCIALES",
        "pdf_content" : [
            {
                "nombre_collapse" : "Información sobre los montos pagados durante el periodo por concepto Ayudas Sociales y Subsidios 1er",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/InformaciónsobrelosmontospagadosduranteelperiodoporconceptoAyudasSocialesySubsidios1er.pdf",
            },
            {
                "nombre_collapse" : "Información sobre los montos pagados durante el periodo por concepto Ayudas Sociales y Subsidios 2do",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/InformaciónsobrelosmontospagadosduranteelperiodoporconceptoAyudasSocialesySubsidios2do.pdf",
            },
            {
                "nombre_collapse" : "Información sobre los montos pagados durante el periodo por concepto Ayudas Sociales y Subsidios 3ero",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/InformaciónsobrelosmontospagadosduranteelperiodoporconceptoAyudasSocialesySubsidios3ero.pdf",
            },
            {
                "nombre_collapse" : "Información sobre los montos pagados durante el periodo por concepto Ayudas Sociales y Subsidios 4to",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/InformaciónsobrelosmontospagadosduranteelperiodoporconceptoAyudasSocialesySubsidios4to.pdf",
            },
            {
                "nombre_collapse" : "Información sobre los montos pagados durante el periodo por concepto Ayudas Sociales y Subsidios 4.1to",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/InformaciónsobrelosmontospagadosduranteelperiodoporconceptoAyudasSocialesySubsidios4.1to.pdf",
            }

        ] 
    },

    
    {
        "anio":"2022 INDICADOR DE RESULTADOS",
        "pdf_content":[
            {
                "nombre_collapse" : "Indicadores de Resultados T1",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/IndicadoresdeResultadosT1.pdf",
            },
            {
                "nombre_collapse" : "Indicadores de Resultados T2",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/IndicadoresdeResultadosT2pdf",
            },
            {
                "nombre_collapse" : "Indicadores de Resultados T3",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/IndicadoresdeResultadosT3.pdf",
            },
            {
                "nombre_collapse" : "Indicadores de Resultados T4",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/IndicadoresdeResultadosT4.pdf",
            }
        ]
    }
]
*/

export const LeyDisciplinaFinancieraAN = [
    {
        "anio" : "2023",
        "pdf_content" : [
            {
                "nombre_collapse" : "F7 Proyecciones egresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/ANUAL/F7%20Proyecciones%20egresos.xlsx",
            },
            {
                "nombre_collapse" : "F7 Proyecciones ingresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/ANUAL/F7 Proyecciones ingresos.xlsx",
            },
            {
                "nombre_collapse" : "F7 Resultados egresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/ANUAL/F7 Resultados egresos.xlsx",
            },
            {
                "nombre_collapse" : "F7 Resultados ingresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/ANUAL/F7 Resultados ingresos.xlsx",
            },
            {
                "nombre_collapse" : "F8 Informe Sobre Estudios Actuariales.xlsx",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/ANUAL/F8 Informe Sobre Estudios Actuariales.xlsx",
            }

        ]  
    },
    {
        "anio" : "2022",
        "pdf_content" : [
            {
                "nombre_collapse" : "F7 Proyectos y Resultados de Ingresos y Egresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/F7ProyectosyResultadosdeIngresosyEgresos.xls",
            },
            {
                "nombre_collapse" : "F8 Informe sobre Estudios Actuariales y la Guia Cumplimiento",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/1. INFORMACION CONTABLE/F8InformesobreEstudiosActuarialesylaGuiaCumplimiento.xls",
            }

        ]  
    },


];

export const LeyDisciplinaFinancieraTM = [
    
    {
        "anio" : "2023-PRIMER TRIMESTRE",
        "trimestre" : [
            {
                "trimestreNombre" : "F1 Estado de Situacion Financiera Detallado",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/1RO/F1 Estado de Situacion Financiera Detallado.xls",
                        //"pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Inventarios/InventarioBienesMuebles.pdf",
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F2 Informe Analitico de la Deuda y Otros Pasivos",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/1RO/F2 Informe Analitico de la Deuda y otros pasivos.xls",
                        //"pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Inventarios/InventarioBienesMuebles.pdf",
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F3 Informe Analitico de Obligaciones Diferentes de Financiamiento",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/1RO/F3 Informe analitico de obligaciones diferentes financiamiento.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F4 Balance Presupuestario",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/1RO/F4 Balance presupuestario.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]    
            },
            {
                "trimestreNombre" : "F5 Estado Analitico de Ingresos Detallado",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/1RO/F5 Estado analitico de ingresos detallado.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F6 Estado Analitico del Ejercicio del Presupuesto COG",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/1RO/F6 Estado analitico del ejercicio del presupuesto COG.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F6 Estado del ejercicio del presupuesto CA",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/1RO/F6 Estado del ejercicio del presupuesto CA.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F6 Estado del ejercicio del presupuesto CF",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/1RO/F6 Estado del ejercicio del presupuesto CF.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F6 Estado del ejercicio del presupuesto CSPC.xlsx",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/1RO/F6 Estado del ejercicio del presupuesto CSPC.xlsx",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2023-SEGUNDO TRIMESTRE",
        "trimestre" : [
            {
                "trimestreNombre" : "F1 Estado de Situacion Financiera Detallado",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/2DO/F1 Estado de Situacion Financiera Detallado.xls",
                        //"pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Inventarios/InventarioBienesMuebles.pdf",
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F2 Informe Analitico de la Deuda y Otros Pasivos",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/2DO/F2 Informe Analitico de la Deuda y otros pasivos.xls",
                        //"pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Inventarios/InventarioBienesMuebles.pdf",
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F3 Informe Analitico de Obligaciones Diferentes de Financiamiento",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/2DO/F3 Informe analitico de obligaciones diferentes financiamiento.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F4 Balance Presupuestario",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/2DO/F4 Balance presupuestario.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]    
            },
            {
                "trimestreNombre" : "F5 Estado Analitico de Ingresos Detallado",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/2DO/F5 Estado analitico de ingresos detallado.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F6 Estado Analitico del Ejercicio del Presupuesto COG",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/2DO/F6 Estado analitico del ejercicio del presupuesto COG.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F6 Estado del ejercicio del presupuesto CA",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/2DO/F6 Estado del ejercicio del presupuesto CA.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F6 Estado del ejercicio del presupuesto CF",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/2DO/F6 Estado del ejercicio del presupuesto CF.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F6 Estado del ejercicio del presupuesto CSPC.xlsx",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/2DO/F6 Estado del ejercicio del presupuesto CSPC.xlsx",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },{
        "anio" : "2023-TERCER TRIMESTRE",
        "trimestre" : [
            {
                "trimestreNombre" : "F1 Estado de Situacion Financiera Detallado",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/3ER/F1 Estado de Situacion Financiera Detallado.xls",
                        //"pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Inventarios/InventarioBienesMuebles.pdf",
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F2 Informe Analitico de la Deuda y Otros Pasivos",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/3ER/F2 Informe Analitico de la Deuda y otros pasivos.xls",
                        //"pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Inventarios/InventarioBienesMuebles.pdf",
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F3 Informe Analitico de Obligaciones Diferentes de Financiamiento",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/3ER/F3 Informe analitico de obligaciones diferentes financiamiento.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F4 Balance Presupuestario",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/3ER/F4 Balance presupuestario.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]    
            },
            {
                "trimestreNombre" : "F5 Estado Analitico de Ingresos Detallado",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/3ER/F5 Estado analitico de ingresos detallado.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F6 Estado Analitico del Ejercicio del Presupuesto COG",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/3ER/F6 Estado analitico del ejercicio del presupuesto COG.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F6 Estado del ejercicio del presupuesto CA",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/3ER/F6 Estado del ejercicio del presupuesto CA.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F6 Estado del ejercicio del presupuesto CF",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/3ER/F6 Estado del ejercicio del presupuesto CF.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F6 Estado del ejercicio del presupuesto CSPC.xlsx",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/3ER/F6 Estado del ejercicio del presupuesto CSPC.xlsx",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },

    {
        "anio" : "2023-CUARTO TRIMESTRE",
        "trimestre" : [
            {
                "trimestreNombre" : "F1 Estado de Situacion Financiera Detallado",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/4TO/F1 Estado de Situacion Financiera Detallado.xls",
                        //"pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Inventarios/InventarioBienesMuebles.pdf",
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F2 Informe Analitico de la Deuda y Otros Pasivos",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/4TO/F2 Informe Analitico de la Deuda y otros pasivos.xls",
                        //"pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Inventarios/InventarioBienesMuebles.pdf",
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F3 Informe Analitico de Obligaciones Diferentes de Financiamiento",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/4TO/F3 Informe analitico de obligaciones diferentes financiamiento.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F4 Balance Presupuestario",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/4TO/F4 Balance presupuestario.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]    
            },
            {
                "trimestreNombre" : "F5 Estado Analitico de Ingresos Detallado",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/4TO/F5 Estado analitico de ingresos detallado.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F6 Estado Analitico del Ejercicio del Presupuesto COG",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/4TO/F6 Estado analitico del ejercicio del presupuesto COG.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F6 Estado del ejercicio del presupuesto CA",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/4TO/F6 Estado del ejercicio del presupuesto CA.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F6 Estado del ejercicio del presupuesto CF",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/4TO/F6 Estado del ejercicio del presupuesto CF.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F6 Estado del ejercicio del presupuesto CSPC.xlsx",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/LEY%20DE%20DISCIPLINA%20FINANCIERA/TRIMESTRAL/4TO/F6 Estado del ejercicio del presupuesto CSPC.xlsx",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2022-PRIMER TRIMESTRE",
        "trimestre" : [
            {
                "trimestreNombre" : "F1 Estado de Situacion Financiera Detallado",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/1RO/F1EstadodeSituacionFinancieraDetallado.xls",
                        //"pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Inventarios/InventarioBienesMuebles.pdf",
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F2 Informe Analitico de la Deuda y Otros Pasivos",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/1RO/F2InformeAnaliticodelaDeudayOtrosPasivos.xls",
                        //"pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Inventarios/InventarioBienesMuebles.pdf",
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F3 Informe Analitico de Obligaciones Diferentes de Financiamiento",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/1RO/F3InformeAnaliticodeObligacionesDiferenctesdeFinanciamiento.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F4 Balance Presupuestario",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/1RO/F4BalancePresupuestario.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]    
            },
            {
                "trimestreNombre" : "F5 Estado Analitico de Ingresos Detallado",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/1RO/F5EstadoAnaliticodeIngresosDetallado.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F6 Estado Analitico del Ejercicio del Presupuesto de Egresos Detallado",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/1RO/F6EstadoAnaliticodelEjerciciodelPresupuestodeEgresosDetallado.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2022-SEGUNDO TRIMESTRE",
        "trimestre" : [
            {
                "trimestreNombre" : "F1 Estado de Situacion Financiera Detallado",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/2DO/F1EstadodeSituacionFinancieraDetallado.xls",
                        //"pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Inventarios/InventarioBienesMuebles.pdf",
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F2 Informe Analitico de la Deuda y Otros Pasivos",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/2DO/F2InformeAnaliticodelaDeudayOtrosPasivos.xls",
                        //"pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Inventarios/InventarioBienesMuebles.pdf",
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F3 Informe Analitico de Obligaciones Diferentes de Financiamiento",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/2DO/F3InformeAnaliticodeObligacionesDiferenctesdeFinanciamiento.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F4 Balance Presupuestario",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/2DO/F4BalancePresupuestario.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]    
            },
            {
                "trimestreNombre" : "F5 Estado Analitico de Ingresos Detallado",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/2DO/F5EstadoAnaliticodeIngresosDetallado.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F6 Estado Analitico del Ejercicio del Presupuesto de Egresos Detallado",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/2DO/F6EstadoAnaliticodelEjerciciodelPresupuestodeEgresosDetallado.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },{
        "anio" : "2022-TERCER TRIMESTRE",
        "trimestre" : [
            {
                "trimestreNombre" : "F1 Estado de Situacion Financiera Detallado",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/3RO/F1EstadodeSituacionFinancieraDetallado.xls",
                        //"pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Inventarios/InventarioBienesMuebles.pdf",
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F2 Informe Analitico de la Deuda y Otros Pasivos",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/3RO/F2InformeAnaliticodelaDeudayOtrosPasivos.xls",
                        //"pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Inventarios/InventarioBienesMuebles.pdf",
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F3 Informe Analitico de Obligaciones Diferentes de Financiamiento",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/3RO/F3InformeAnaliticodeObligacionesDiferenctesdeFinanciamiento.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F4 Balance Presupuestario",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/3RO/F4BalancePresupuestario.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]    
            },
            {
                "trimestreNombre" : "F5 Estado Analitico de Ingresos Detallado",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/3RO/F5EstadoAnaliticodeIngresosDetallado.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F6 Estado Analitico del Ejercicio del Presupuesto de Egresos Detallado",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/3RO/F6EstadoAnaliticodelEjerciciodelPresupuestodeEgresosDetallado.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },

    {
        "anio" : "2022-CUARTO TRIMESTRE",
        "trimestre" : [
            {
                "trimestreNombre" : "F1 Estado de Situacion Financiera Detallado",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/4TO/F1EstadodeSituacionFinancieraDetallado.xls",
                        //"pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T1/Inventarios/InventarioBienesMuebles.pdf",
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F2 Informe Analitico de la Deuda y Otros Pasivos",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/4TO/F2InformeAnaliticodelaDeudayOtrosPasivos.xls",
                        //"pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/informacioncontable/T2/Inventarios/InventarioBienesMuebles.pdf",
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F3 Informe Analitico de Obligaciones Diferentes de Financiamiento",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/4TO/F3InformeAnaliticodeObligacionesDiferenctesdeFinanciamiento.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F4 Balance Presupuestario",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/4TO/F4BalancePresupuestario.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]    
            },
            {
                "trimestreNombre" : "F5 Estado Analitico de Ingresos Detallado",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/4TO/F5EstadoAnaliticodeIngresosDetallado.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "F6 Estado Analitico del Ejercicio del Presupuesto de Egresos Detallado",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/cuentapublica/6. LEY DE DISCIPLINA FINANCIERA/4TO/F6EstadoAnaliticodelEjerciciodelPresupuestodeEgresosDetallado.xls",
                        //"pdf" : "https://www.google.com"
                        //"pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },


];

export const ManualdeContabilidadGubernamental = [
    
    {
        "anio" : "Manual de Contabilidad Gubernamental",
        "trimestre" : [
            {
                "trimestreNombre" : "Manual",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/Manual de Contabilidad Guberamental.pdf"
                    }        
                ]
            },
        
        ],
    },

    //{
      
      //  "anio" : "Manual de Contabilidad Gubernamental",
        //"pdf_content" : [
          //  {
            //    "nombre_collapse" : "Manual",
              //  "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Armonizacioncontable/Manual de Contabilidad Guberamental.pdf",
            //},
          
        //]  
    //},



];

//Normas (Título Quinta LGCG)

//export const AprobacionLeyIngresosPresupuestos  = [
  

//Programa Anual de Adquisiciones



//Calendario de Gastos

export const CalendarioGastos = [
    {
        "anio" : "Calendario 2022",
        "trimestre" : [
            {
                "trimestreNombre" : "",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/2do_trim/g_o/Calendario%20de%20gastos%202022.pdf"
                    }
                ]
            },
        ],
    },

];

//Recursos Federales
export const RecursosFederales = [
    {
        "anio" : "2024",
        "trimestre" : [
            /*{
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/cuentapublica/Armonizacioncontable/RecursosFederales/1er trimestre 2024.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/cuentapublica/Armonizacioncontable/RecursosFederales/2do trimestre 2024.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/cuentapublica/Armonizacioncontable/RecursosFederales/3er trimestre 2024.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/cuentapublica/Armonizacioncontable/RecursosFederales/4to. trimestre 2024.pdf",
                    }
                ]
            }*/
        ],
    },
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2023/cuentapublica/Armonizacioncontable/RecursosFederales/1er trimestre 2023.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2023/cuentapublica/Armonizacioncontable/RecursosFederales/2do trimestre 2023.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2023/cuentapublica/Armonizacioncontable/RecursosFederales/3er trimestre 2023.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2023/cuentapublica/Armonizacioncontable/RecursosFederales/4to. trimestre 2023.pdf",
                    }
                ]
            }
        ],
    }
];